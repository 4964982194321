import { array, option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useCallback, useLayoutEffect, useRef, useState } from "react";

export function useTaskDelayed(delayMs: number, task: Function) {
  const taskRef = useRef<null | number>(null);

  const cancel = useCallback(() => {
    if (!taskRef.current) {
      return;
    }

    clearTimeout(taskRef.current);
  }, []);

  const start = useCallback(() => {
    cancel();

    taskRef.current = setTimeout(task, delayMs);
  }, [cancel, task]);

  // We use useLayoutEffect() to conform with React 17 hooks lifecycle.
  useLayoutEffect(() => {
    return cancel;
  }, [cancel]);

  return { start, cancel };
}

export function useAccordionSteps<I>(steps: Array<{ id: I }>) {
  const [stepIndex, setStepIndex] = useState<Option<number>>(option.some(0));

  const onStepIndex = setStepIndex;

  const onStepId = useCallback(
    (id: I) => {
      setStepIndex(
        pipe(
          steps,
          array.findIndex(it => it.id === id)
        )
      );
    },
    [steps]
  );

  const onNextStep = useCallback(() => {
    setStepIndex(
      pipe(
        stepIndex,
        option.map(it => Math.min(steps.length, it + 1))
      )
    );
  }, [steps.length, stepIndex]);

  return { onStepId, onStepIndex, onNextStep, stepIndex };
}
