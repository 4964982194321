import { eq, taskEither } from "fp-ts";
import * as t from "io-ts";
import { UUID } from "io-ts-types/UUID";
import { constFalse, constTrue } from "fp-ts/function";
import { apiCall } from "../../APICall";
import { RemoteData } from "../../RemoteData";
import {
  MobileRecipientNumber,
  MobileRecipientType,
} from "../../UploadDocuments/domain";

// ReadStatus //////////////////////////////////////////////////////////////////

export const ReadStatusInput = t.void;
export const ReadStatusOutput = t.type({
  status: t.keyof({
    WaitingForConnection: null,
    MobileConnected: null,
    UserIdCreated: null,
    PinCreated: null,
    PasswordCreated: null,
    ConnectionLost: null,
  }),
});
export const ReadStatusError = t.unknown;
export const readStatus = apiCall({
  path: [
    "clients",
    "identification",
    "mobileCreateProfile",
    "credentials",
    "status",
  ],
  inputCodec: ReadStatusInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ReadStatusOutput,
  errorCodec: ReadStatusError,
});

export type ReadStatusOutput = t.TypeOf<typeof ReadStatusOutput>;
export type ReadStatusOutputType = ReadStatusOutput["status"];

// SubmittedCredentialsViaMobile  //////////////////////////////////////////////////////////////

export const submittedCredentialsViaMobile = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "client",
    "submittedCredentialsViaMobile",
  ],
  outputCodec: t.unknown,
});

// ReadRecipients //////////////////////////////////////////////////////////////

export const ReadRecipientsInput = t.type({
  linkPurpose: t.literal("addingCredentials"),
});
const ReadRecipientsOutput = t.record(
  MobileRecipientType,
  MobileRecipientNumber
);
export const ReadRecipientsError = t.unknown;
export const readRecipients = apiCall({
  path: ["clients", "identification", "mobileCreateProfile", "recipients"],
  inputCodec: ReadRecipientsInput,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: ReadRecipientsOutput,
  errorCodec: ReadRecipientsError,
});

export type ReadRecipientsQuery = RemoteData<
  t.TypeOf<typeof ReadRecipientsError>,
  t.TypeOf<typeof ReadRecipientsOutput>
>;

// ReadLink ////////////////////////////////////////////////////////////////////

export const ReadLinkInput = t.type({
  id: t.string,
});
export const ReadLinkOutput = t.type({
  flowId: UUID,
});
export const ReadLinkError = t.unknown;
export const readLink = apiCall({
  path: ["clients", "identification", "mobileCreateProfile", "link", "verify"],
  inputCodec: ReadLinkInput,
  inputEq: eq.getStructEq({ id: eq.eqString }),
  outputCodec: ReadLinkOutput,
  errorCodec: ReadLinkError,
});

// ReadParameters //////////////////////////////////////////////////////////////

export const ReadParametersInput = t.type({
  id: t.string,
});
export const ReadParametersOutput = t.type({
  riskyActivitiesOrPep: t.boolean,
});
export const ReadParametersError = t.unknown;
export const readParameters = apiCall({
  path: ["clients", "identification", "mobileCreateProfile", "parameters"],
  inputCodec: ReadParametersInput,
  inputEq: eq.getStructEq({ id: eq.eqString }),
  outputCodec: ReadParametersOutput,
  errorCodec: ReadParametersError,
});

export type ReadParametersQuery = RemoteData<
  t.TypeOf<typeof ReadParametersError>,
  t.TypeOf<typeof ReadParametersOutput>
>;

// SendLink ////////////////////////////////////////////////////////////////////

export const SendLinkInput = t.void;
export const SendLinkOutput = t.type({
  attemptsLeft: t.number,
});
export const SendLinkError = t.type({
  id: t.keyof({
    InvalidFlowId: null,
    MaxLinksAttemptsReached: null,
  }),
});
export const sendLink = apiCall({
  path: ["clients", "identification", "mobileCreateProfile", "link"],
  inputCodec: SendLinkInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: SendLinkOutput,
  errorCodec: SendLinkError,
});

// UpdateUserId ////////////////////////////////////////////////////////////////

export const UpdateUserIdInput = t.type({
  userId: t.string,
});
export const UpdateUserIdOutput = t.null;
export const UpdateUserIdError = t.unknown;
export const updateUserId = apiCall({
  path: [
    "clients",
    "identification",
    "mobileCreateProfile",
    "credentials",
    "userId",
  ],
  inputCodec: UpdateUserIdInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: UpdateUserIdOutput,
  errorCodec: UpdateUserIdError,
});

export type UpdateUserIdCommand = taskEither.TaskEither<
  t.TypeOf<typeof UpdateUserIdError>,
  t.TypeOf<typeof UpdateUserIdOutput>
>;

// UpdatePin ///////////////////////////////////////////////////////////////////

export const UpdatePinInput = t.type({
  securityPin: t.string,
});
export const UpdatePinOutput = t.null;
export const UpdatePinError = t.unknown;
export const updatePin = apiCall({
  path: [
    "clients",
    "identification",
    "mobileCreateProfile",
    "credentials",
    "securityPin",
  ],
  inputCodec: UpdatePinInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: UpdatePinOutput,
  errorCodec: UpdatePinError,
});

export type UpdatePinCommand = taskEither.TaskEither<
  t.TypeOf<typeof UpdatePinError>,
  t.TypeOf<typeof UpdatePinOutput>
>;

// UpdatePassword //////////////////////////////////////////////////////////////

export const UpdatePasswordInput = t.type({
  passwordForCommunication: t.string,
});
export const UpdatePasswordOutput = t.null;
export const UpdatePasswordError = t.unknown;
export const updatePassword = apiCall({
  path: [
    "clients",
    "identification",
    "mobileCreateProfile",
    "credentials",
    "passwordForCommunication",
  ],
  inputCodec: UpdatePasswordInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: UpdatePasswordOutput,
  errorCodec: UpdatePasswordError,
});

export type UpdatePasswordCommand = taskEither.TaskEither<
  t.TypeOf<typeof UpdatePasswordError>,
  t.TypeOf<typeof UpdatePasswordOutput>
>;
