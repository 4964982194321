import {
  Box,
  ContentRow,
  Heading,
  LocalizedString,
  Space,
  Stack,
  useIsTouchScreen,
} from "design-system";
import { FormattedMessage, useFormatMessage } from "../../intl";
import { Summary } from "./Summary";
import { Download } from "./Download";
import { boolean } from "fp-ts";
import { constNull, pipe } from "fp-ts/function";
import { WithPromotionBenefit } from "../../PhoneAndEmailVerification/WithPromotionBenefit";
import { useBranchExperienceContext } from "../../BranchExperience/BranchExperienceContext";
import { PendingActions } from "./PendingActions";
import { useQuery } from "../../useAPI";
import * as summaryApi from "./api";
import * as remoteData from "../../RemoteData";

type Props = {
  finalChecksComplete: boolean;
  checkPendingActions: boolean;
  heading: {
    title: LocalizedString;
    description: LocalizedString;
  };
  isChild: boolean;
  isCf?: boolean;
  isSavingAaccount?: boolean;
};

export function SummaryAndDownload(props: Props) {
  const formatMessage = useFormatMessage();
  const { branchExperienceFeaturesActive } = useBranchExperienceContext();
  const isTouchScreen = useIsTouchScreen();
  const [pendingActions] = useQuery(summaryApi.pendingActions, {
    checkPendingActions: props.checkPendingActions,
  });
  const isCf = !!props.isCf;

  return pipe(
    pendingActions,
    remoteData.fold(
      () => <FormattedMessage id="UKontoWizard.loading" />,
      () => <FormattedMessage id="UKontoWizard.error" />,
      (success: summaryApi.PendingActionsOutput) => {
        return pipe(
          branchExperienceFeaturesActive && !isTouchScreen,
          boolean.fold(
            () =>
              props.isChild ? (
                <Box column shrink>
                  <Heading size="medium" weight="regular">
                    {formatMessage("SummaryAndDownload.title")}
                  </Heading>
                  <Space units={6} />
                  <Stack units={21} column>
                    {success.sbRelevant && <Download stepNo={1} isCf={isCf} />}
                    <WithPromotionBenefit>
                      {promotion => (
                        <Summary
                          stepNo={success.sbRelevant ? 2 : 1}
                          finalChecksComplete={props.finalChecksComplete}
                          promotion={promotion}
                          heading={props.heading}
                        />
                      )}
                    </WithPromotionBenefit>
                  </Stack>
                  {props.checkPendingActions && (
                    <PendingActions
                      stepNo={success.sbRelevant ? 3 : 2}
                      pendingActions={success}
                      isSavingsAccount={props.isSavingAaccount === true}
                    />
                  )}
                  <Space units={18} />
                </Box>
              ) : (
                <ContentRow type="lateral-margins">
                  <Box column shrink>
                    <Heading size="medium" weight="regular">
                      {formatMessage("SummaryAndDownload.title")}
                    </Heading>
                    <Space units={6} />
                    <Stack units={21} column>
                      {success.sbRelevant && (
                        <Download stepNo={1} isCf={isCf} />
                      )}
                      <WithPromotionBenefit>
                        {promotion => (
                          <Summary
                            stepNo={success.sbRelevant ? 2 : 1}
                            finalChecksComplete={props.finalChecksComplete}
                            promotion={promotion}
                            heading={props.heading}
                          />
                        )}
                      </WithPromotionBenefit>
                    </Stack>
                    {props.checkPendingActions && (
                      <PendingActions
                        stepNo={success.sbRelevant ? 3 : 2}
                        pendingActions={success}
                        isSavingsAccount={props.isSavingAaccount === true}
                      />
                    )}
                    <Space units={18} />
                  </Box>
                </ContentRow>
              ),
            () =>
              pipe(
                props.checkPendingActions &&
                  (!success.emailVerified ||
                    success.crsRelevant ||
                    success.fatcaRelevant ||
                    success.overdraftAvailability ||
                    success.sbRelevant),
                boolean.fold(constNull, () =>
                  props.isChild ? (
                    <Box column shrink>
                      <Heading size="medium" weight="regular">
                        {formatMessage(
                          "SummaryAndDownload.branchExperience.title"
                        )}
                      </Heading>
                      <Space units={12} />
                      <PendingActions
                        stepNo={1}
                        pendingActions={success}
                        isSavingsAccount={props.isSavingAaccount === true}
                      />
                    </Box>
                  ) : (
                    <ContentRow type="lateral-margins">
                      <Box column shrink>
                        <Heading size="medium" weight="regular">
                          {formatMessage(
                            "SummaryAndDownload.branchExperience.title"
                          )}
                        </Heading>
                        <Space units={12} />
                        <PendingActions
                          stepNo={1}
                          pendingActions={success}
                          isSavingsAccount={props.isSavingAaccount === true}
                        />
                      </Box>
                    </ContentRow>
                  )
                )
              )
          )
        );
      }
    )
  );
}
