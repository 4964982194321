import { useEffect, useState } from "react";
import { useCommand } from "../../useAPI";
import { useCredentialsMainApi } from "./CredentialsApi";
import {
  CredentialsCloseManager,
  CredentialsConnectedDialog,
  CredentialsErrorDialog,
  CredentialsWaitDialog,
} from "./CredentialsMainDialog";
import { CredentialsMainView } from "./CredentialsMainView";
import * as api from "./api";
import { constVoid, pipe } from "fp-ts/function";
import { taskEither } from "fp-ts";
import { IO } from "fp-ts/IO";
import * as remoteData from "../../RemoteData";

export type CredentialsStepsModel = {
  hasUserId: boolean;
  hasPin: boolean;
  hasPassword: boolean;
};

export type CredentialsCustomerModel = {
  isRiskyClient: boolean;
};

export type CredentialsMainModel = CredentialsStepsModel &
  CredentialsCustomerModel;

export const CredentialsDefaultCustomerModel: CredentialsMainModel = {
  hasUserId: true,
  hasPin: true,
  hasPassword: true,
  isRiskyClient: false,
};

export const CredentialsRiskyCustomerModel: CredentialsMainModel = {
  hasUserId: false,
  hasPin: false,
  hasPassword: true,
  isRiskyClient: true,
};

export type CredentialsMainProps = CredentialsMainModel & {
  onClose: () => void;
  onComplete: () => void;
  withCountdown: boolean;
  onRemoteCredentialsExit: IO<unknown>;
  isSLFlow: boolean;
};

export function CredentialsMain(props: CredentialsMainProps) {
  const { onClose: onClosed, onComplete, ...otherProps } = props;
  const [connectionConfirmed, setConnectionConfirmed] = useState(false);
  const {
    linkSent,
    linksQuota,
    phoneNumberQuery,
    onSendLink,
    syncState,
    restartPolling,
    resetLinkSent,
  } = useCredentialsMainApi(props.withCountdown);

  const submittedCredentialsViaMobile = useCommand(
    api.submittedCredentialsViaMobile
  );

  useEffect(() => {
    if (syncState.status !== "PasswordCreated") {
      // PasswordCreated is last step.
      return;
    }

    // first the BFF requires acknowledgement that Credentials were created
    // then we can proceed as Completed
    pipe(
      submittedCredentialsViaMobile(),
      taskEither.chain(() => taskEither.rightIO(onComplete))
    )();
  }, [syncState.status, onComplete]);

  const [maxAttempts, setMaxAttempts] = useState(false);

  pipe(
    phoneNumberQuery,
    remoteData.fold(
      constVoid,
      () => constVoid,
      recipients => {
        if (maxAttempts !== recipients.Client.disabled) {
          setMaxAttempts(recipients.Client.disabled);
        }
      }
    )
  );

  return (
    <>
      <CredentialsMainView
        linkSent={linkSent}
        onSendLink={onSendLink}
        syncState={syncState}
        phoneNumberQuery={phoneNumberQuery}
        {...otherProps}
      />

      <CredentialsCloseManager onClose={onClosed}>
        {onClose => {
          switch (syncState.status) {
            case "WaitingForConnection": {
              if (!linkSent) {
                // Link has not been sent yet. We don't have to show the retry dialog.
                return null;
              }

              // Link has been sent. We show the retry dialog.
              return (
                <CredentialsWaitDialog
                  linksQuota={linksQuota}
                  phoneNumberQuery={phoneNumberQuery}
                  onClose={onClose}
                  onSendLink={onSendLink}
                />
              );
            }
            case "MobileConnected":
            case "UserIdCreated":
            case "PinCreated":
            case "PasswordCreated": {
              if (connectionConfirmed) {
                // Connection has been confirmed. We don't have to show the confirmation dialog.
                return null;
              }

              return (
                <CredentialsConnectedDialog
                  onClose={() => setConnectionConfirmed(true)}
                />
              );
            }
            case "ConnectionLost": {
              return maxAttempts ? null : (
                <CredentialsErrorDialog
                  onClose={onClose}
                  onRemoteCredentialsExit={() => {
                    restartPolling();
                    if (
                      syncState.status === "WaitingForConnection" ||
                      syncState.status === "ConnectionLost"
                    ) {
                      resetLinkSent();
                    }
                    props.onRemoteCredentialsExit();
                  }}
                  isSLFlow={props.isSLFlow}
                />
              );
            }
          }
        }}
      </CredentialsCloseManager>
    </>
  );
}
