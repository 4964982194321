import { useEffect } from "react";
import {
  Box,
  Space,
  Heading,
  FormSection,
  PasswordFieldWithCriteria,
  PasswordStrength,
  Form,
  FormRow,
  PasswordField,
  LocalizedString,
  useForm,
  passwordStrengthCalculator,
  TextChildren,
} from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { option, nonEmptyArray, record, eq } from "fp-ts";
import { useFormatMessage } from "../../../intl";
import { PersonalDataProcessingDisclaimer } from "../../../Common/PersonalDataProcessingDisclaimer/PersonalDataProcessingDisclaimer";
import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { useCriteria } from "../../../Common/useCriteria";
import { useValidators } from "../../../Common/useValidators";
import { TaskEither } from "fp-ts/TaskEither";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { useVirtualFormSubmit } from "../../../Common/VirtualKeyboard/virtualKeyboardUtils";
import { usePropagateHasChanged } from "../../../ClientProfile/usePropagateHasChanged";

type Props = {
  submitLabel: LocalizedString;
  onComplete: (password: NonEmptyString) => TaskEither<unknown, unknown>;
  error: Option<TextChildren>;
  disabled?: boolean;
  setUkontoPasswordStep?: () => unknown;
  onHasChanged?: (hasChanged: boolean) => unknown;
};

export function SecurityPassword(props: Props) {
  const formatMessage = useFormatMessage();
  const { clientPasswordCriteria, passwordStrengthLabel } = useCriteria();
  const { validPassword, passwordMatches } = useValidators();
  const numberOfPasswordCriteria = pipe(
    clientPasswordCriteria,
    record.keys,
    a => a.length
  );

  useEffect(() => {
    const { setUkontoPasswordStep } = props;
    setUkontoPasswordStep && setUkontoPasswordStep();
    // Reason: call the setUkontoKYCStep function once instead of multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fieldProps, handleSubmit, setValues, values } = useForm(
    {
      initialValues: {
        password: { value: "", strength: "weak" as PasswordStrength },
        repeatedPassword: "",
      },
      fieldValidators: ({ password }) => ({
        password: validPassword,
        repeatedPassword: passwordMatches(password),
      }),
    },
    {
      onSubmit: ({ password }) => props.onComplete(password),
    }
  );
  useVirtualFormSubmit(handleSubmit);

  usePropagateHasChanged<{ value: string; strength: PasswordStrength }>({
    initialValue: { value: "", strength: "weak" as PasswordStrength },
    fieldProps: fieldProps("password"),
    equality: {
      equals: (a, b) => eq.eqString.equals(a.value, b.value),
    },
    onHasChanged: props.onHasChanged,
  });

  usePropagateHasChanged<string>({
    initialValue: "",
    fieldProps: fieldProps("repeatedPassword"),
    equality: eq.eqString,
    onHasChanged: props.onHasChanged,
  });

  const formErrors = pipe(
    props.error,
    option.map(error => nonEmptyArray.of(error))
  );

  return (
    <Box column shrink>
      <Heading size="x-small" weight="medium" color={palette.neutral700}>
        {formatMessage("CreateProfile.securityPassword.description.primary")}
      </Heading>
      <Space units={14} />

      <Form
        submitButton={{
          action: handleSubmit,
          label: props.submitLabel,
          disabled: props.disabled,
        }}
      >
        <FormSection errors={formErrors}>
          <FormRow type="1-1">
            <PasswordFieldWithCriteria
              {...fieldProps("password")}
              name="password"
              placeholder={formatMessage(
                "CreateProfile.securityPassword.placeholder"
              )}
              label={formatMessage("CreateProfile.securityPassword.label")}
              showHideButton={option.some({
                hideLabel: formatMessage("Hide"),
                showLabel: formatMessage("Show"),
              })}
              onChange={password =>
                setValues({
                  password,
                  // re-trigger validation of repeatedPassword
                  repeatedPassword: values.repeatedPassword,
                })
              }
              criteria={clientPasswordCriteria}
              strength={passwordStrengthCalculator({
                baseCriteria: [],
                goodThreshold: numberOfPasswordCriteria,
                strongThreshold: numberOfPasswordCriteria,
              })}
              strengthLabel={passwordStrengthLabel}
              disabled={props.disabled}
            />
            <Box />
          </FormRow>
          <FormRow type="1-1">
            <PasswordField
              {...fieldProps("repeatedPassword")}
              name="repeatedPassword"
              placeholder={formatMessage(
                "CreateProfile.securityPassword.repeat.placeholder"
              )}
              label={formatMessage(
                "CreateProfile.securityPassword.repeat.label"
              )}
              showHideButton={option.some({
                hideLabel: formatMessage("Hide"),
                showLabel: formatMessage("Show"),
              })}
              disabled={props.disabled}
            />
            <Box />
          </FormRow>
        </FormSection>

        <PersonalDataProcessingDisclaimer
          label={formatMessage(
            "CreateProfile.securityPassword.personalData.agreement"
          )}
        />
      </Form>
    </Box>
  );
}
