import * as t from "io-ts";
import { sharedReducerConfig } from "../BranchExperience/useSharedReducer";
import { Option } from "fp-ts/Option";
import { option as optionCodec } from "io-ts-types/lib/option";
import { LocalizedString } from "design-system";

export const UKontoPackageType = t.keyof({
  BASIC: true,
  MIDDLE: true,
  UPPER: true,
});

const UKontoFirstPartState = t.type({
  currentRoute: t.literal("UKontoFirstPart"),
  chosenCard: optionCodec(UKontoPackageType),
});

const OneClickJourneyState = t.type({
  currentRoute: t.literal("OneClickJourney"),
  chosenCard: optionCodec(UKontoPackageType),
});

const FinancialIntermediationState = t.type({
  currentRoute: t.literal("FinancialIntermediation"),
  chosenCard: UKontoPackageType,
  text: optionCodec(LocalizedString),
});

const FinancialIntermediationSubmitState = t.type({
  currentRoute: t.literal("FinancialIntermediationSubmit"),
  chosenCard: UKontoPackageType,
  text: optionCodec(LocalizedString),
});

export type UKontoPackageType = t.TypeOf<typeof UKontoPackageType>;

const State = t.union([
  UKontoFirstPartState,
  OneClickJourneyState,
  FinancialIntermediationState,
  FinancialIntermediationSubmitState,
]);

export type State = t.TypeOf<typeof State>;
const SetRouteAction = t.type({
  type: t.literal("SetRoute"),
  payload: State,
});
const SetChosenCardAction = t.type({
  type: t.literal("SetChosenCard"),
  payload: optionCodec(UKontoPackageType),
});
const SubmitFinancialIntermediationAction = t.type({
  type: t.literal("SubmitFinancialIntermediation"),
});

export const Action = t.union([
  SetRouteAction,
  SetChosenCardAction,
  SubmitFinancialIntermediationAction,
]);

export type Action = t.TypeOf<typeof Action>;

export function setRouteAction(payload: State): Action {
  return {
    type: "SetRoute",
    payload,
  };
}
export function setChosenCardAction(
  ukontoPackage: Option<UKontoPackageType>
): Action {
  return {
    type: "SetChosenCard",
    payload: ukontoPackage,
  };
}
export const setFinancialIntermediationSubmitAction: Action = {
  type: "SubmitFinancialIntermediation",
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SetRoute":
      return action.payload;
    case "SetChosenCard":
      if (
        state.currentRoute !== "FinancialIntermediation" &&
        state.currentRoute !== "FinancialIntermediationSubmit"
      ) {
        return { ...state, chosenCard: action.payload };
      }
      return state;
    case "SubmitFinancialIntermediation":
      if (state.currentRoute === "FinancialIntermediation") {
        return {
          ...state,
          currentRoute: "FinancialIntermediationSubmit",
        };
      }
      return state;
  }
}

export const reducerConfig = sharedReducerConfig(
  "UKontoFirstPart",
  State,
  Action,
  reducer
);
