import cx from "classnames";
import { array, option, record } from "fp-ts";
import { constVoid, flow, pipe } from "fp-ts/function";
import {
  ActionButtonGroup,
  Banner,
  Body,
  Box,
  Button,
  Card,
  Dialog,
  Heading,
  ProgressIcon,
  Space,
  Stack,
  SuccessIcon,
  ToDoIcon,
  UnorderedList,
  WarningIcon,
} from "design-system";
import { palette, PaletteColor } from "design-system/lib/styleConstants";
import { useMemo, useState } from "react";
import { ReadRecipientsQuery, ReadStatusOutput } from "./api";
import { LocaleKey, useFormatMessage } from "../../intl";
import { CredentialsMainModel, CredentialsStepsModel } from "./CredentialsMain";
import * as Style from "./style.treat";
import { SmartKeyActivationDialog } from "../../Common/Dialogs/SmartKeyActivationDialog";
import { useCriteria } from "../../Common/useCriteria";
import { PersonalDataProcessingDisclaimer } from "../../Common/PersonalDataProcessingDisclaimer/PersonalDataProcessingDisclaimer";
import * as remoteData from "../../RemoteData";
import { CredentialsBannerCommonProps } from "./CredentialsMainDialog";

const CredentialsDefaultSteps: Array<CredentialsMainStep> = [
  {
    type: "UserId",
    lifecycle: "Required",
    message: "CreateProfile.userIDTitle",
  },
  {
    type: "Pin",
    lifecycle: "Required",
    message: "CreateProfile.securityPINTitle",
  },
  {
    type: "Password",
    lifecycle: "Required",
    message: "CreateProfile.passwordTitle",
  },
];

export interface CredentialsMainViewModel extends CredentialsMainModel {
  syncState: ReadStatusOutput;
}

export type CredentialsMainViewProps = CredentialsMainViewModel & {
  linkSent: boolean;
  onSendLink: () => void;
  phoneNumberQuery: ReadRecipientsQuery;
};

export function CredentialsMainView(props: CredentialsMainViewProps) {
  const {
    hasUserId,
    hasPin,
    hasPassword,
    isRiskyClient,
    linkSent,
    onSendLink,
    syncState,
  } = props;
  const formatMessage = useFormatMessage();

  const steps = useMemo(() => {
    return computeSteps(syncState, { hasUserId, hasPin, hasPassword });
  }, [syncState, hasUserId, hasPin, hasPassword]);

  const [shouldShowSendLink, setShouldShowLink] = useState(false);
  const [showMaxAttempts, setShowMaxAttempts] = useState(false);

  pipe(
    props.phoneNumberQuery,
    remoteData.fold(
      constVoid,
      () => {
        if (!shouldShowSendLink) setShouldShowLink(true);
      },
      recipients => {
        const newShouldShowSendLink = !recipients.Client.disabled;
        if (shouldShowSendLink !== newShouldShowSendLink) {
          setShouldShowLink(newShouldShowSendLink);
        }
        if (showMaxAttempts !== recipients.Client.disabled) {
          setShowMaxAttempts(recipients.Client.disabled);
        }
      }
    )
  );
  const shouldShowSteps =
    syncState.status !== "WaitingForConnection" &&
    syncState.status !== "ConnectionLost";

  const [
    smartKeyActivationModalOpen,
    setSmarKeyActivationModalOpen,
  ] = useState<boolean>(false);
  const [checkRulesModalOpen, setCheckRulesModalOpen] = useState<boolean>(
    false
  );
  const { securityPinCriteria, clientPasswordCriteria } = useCriteria();

  const pinCriteria = pipe(
    securityPinCriteria,
    record.toArray,
    array.map(([_, { label }]) => {
      return label;
    })
  );

  const pswCriteria = pipe(
    clientPasswordCriteria,
    record.toArray,
    array.map(([_, { label }]) => {
      return label;
    })
  );

  return (
    <Box column hAlignContent="center">
      <Heading size="large" weight="medium" align="center">
        {formatMessage(
          "UKonto.CredentialsCreationMobile.Main.stepCredentialsTitle"
        )}
      </Heading>

      <Space units={4} />

      <Body size="medium" weight="regular" align="left">
        {formatMessage(
          "UKonto.CredentialsCreationMobile.Main.stepCredentialsDescription"
        )}
      </Body>

      <Space units={8} />

      {isRiskyClient && (
        <>
          <Banner
            type="informative"
            title={option.none}
            content={formatMessage(
              "UKonto.CredentialsCreationMobile.Main.stepRiskyDescription"
            )}
            actions={option.none}
            onDismiss={option.none}
          />
          <Space units={8} />
        </>
      )}

      <Card>
        <Stack column divider grow>
          <Body size="big" weight="medium">
            {formatMessage(
              "UKonto.CredentialsCreationMobile.Main.stepCredentialsHeading"
            )}
          </Body>

          <Stack column hAlignContent="center">
            <Space units={8} />

            <Stack column hAlignContent="left" units={8}>
              {shouldShowSendLink && (
                <Box vAlignContent="center">
                  <Body
                    size="medium"
                    weight="regular"
                    color={palette.neutral500}
                  >
                    {formatMessage(
                      "UKonto.CredentialsCreationMobile.Main.stepSendLinkDescription"
                    )}
                  </Body>

                  <Space units={4} />

                  <Button
                    label={formatMessage(
                      "UKonto.CredentialsCreationMobile.Main.stepSendLinkAction"
                    )}
                    variant="primary"
                    size="default"
                    action={onSendLink}
                    disabled={linkSent}
                  />
                </Box>
              )}
              {showMaxAttempts && (
                <Banner
                  {...CredentialsBannerCommonProps}
                  type="warning"
                  content={formatMessage(
                    "UKonto.CredentialsCreationMobile.Main.attemptsReachedWarning"
                  )}
                />
              )}
              {shouldShowSteps && (
                <>
                  <Body
                    size="medium"
                    weight="regular"
                    color={palette.neutral500}
                  >
                    {formatMessage(
                      "UKonto.CredentialsCreationMobile.Main.waitCompletionDescription"
                    )}
                  </Body>

                  <Space units={8} />

                  <Stack column units={6} style={{ margin: "auto" }}>
                    {steps.map((it, idx) => (
                      <Box key={idx} vAlignContent="center">
                        <Box grow>
                          <Heading
                            size="x-small"
                            weight="medium"
                            color={palette.neutral800}
                            className={cx({
                              [Style.Credentials.stepDisabled]:
                                it.lifecycle === "Disabled",
                            })}
                          >
                            {formatMessage(it.message)}
                          </Heading>
                        </Box>

                        <Space units={4} />

                        <Box
                          component="label"
                          vAlignContent="center"
                          style={{ width: "10rem" }}
                        >
                          <it.icon size="default" color={it.iconColor} />

                          <Space units={2} />

                          <Body
                            size="medium"
                            weight="medium"
                            color={it.iconColor}
                          >
                            {formatMessage(it.iconMessage)}
                          </Body>
                        </Box>
                      </Box>
                    ))}
                  </Stack>
                  <Space units={8} />
                  <Box>
                    <ActionButtonGroup
                      actions={[
                        {
                          variant: "secondary",
                          action: () => setCheckRulesModalOpen(true),
                          label: formatMessage(
                            "CreateProfileParent.checkRules"
                          ),
                        },
                        {
                          variant: "secondary",
                          action: () => setSmarKeyActivationModalOpen(true),
                          label: formatMessage(
                            "CreateProfileParent.checkSmartKey"
                          ),
                        },
                      ]}
                      hAlignContent="left"
                    />
                  </Box>
                  <Space units={8} />
                  <Box>
                    <PersonalDataProcessingDisclaimer
                      label={formatMessage(
                        "CreateProfile.mainView.personalData.agreement"
                      )}
                    />
                  </Box>

                  {smartKeyActivationModalOpen && (
                    <SmartKeyActivationDialog
                      onDismiss={() => setSmarKeyActivationModalOpen(false)}
                    />
                  )}
                  {checkRulesModalOpen && (
                    <Dialog
                      variant="left"
                      size="small"
                      title={formatMessage("CheckRules.modal.title")}
                      onDismiss={option.some(() =>
                        setCheckRulesModalOpen(false)
                      )}
                      actions={[]}
                    >
                      <Stack column units={4}>
                        <Stack units={4} column grow shrink>
                          <Body size="medium" weight="medium">
                            {formatMessage("CreateProfile.userIDTitle")}
                          </Body>
                          <UnorderedList size="medium" listStyle="bullet">
                            {[
                              formatMessage("CreateProfile.UserID.suggestion1"),
                              formatMessage("CreateProfile.UserID.suggestion2"),
                              formatMessage("CreateProfile.UserID.suggestion3"),
                            ]}
                          </UnorderedList>
                        </Stack>
                        <Stack units={4} column grow shrink>
                          <Body size="medium" weight="medium">
                            {formatMessage("CreateProfile.securityPINTitle")}
                          </Body>
                          {array.isNonEmpty(pinCriteria) && (
                            <UnorderedList size="medium" listStyle="bullet">
                              {pinCriteria}
                            </UnorderedList>
                          )}
                        </Stack>
                        <Stack units={4} column grow shrink>
                          <Body size="medium" weight="medium">
                            {formatMessage("CreateProfile.passwordTitle")}
                          </Body>
                          {array.isNonEmpty(pswCriteria) && (
                            <UnorderedList size="medium" listStyle="bullet">
                              {pswCriteria}
                            </UnorderedList>
                          )}
                        </Stack>
                      </Stack>
                    </Dialog>
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
}

export function computeSteps(
  syncState: ReadStatusOutput,
  conf: CredentialsStepsModel
): Array<CredentialsMainStepWithStyle> {
  return pipe(
    CredentialsDefaultSteps,
    array.map(
      flow(
        // 1) Initializes the step.
        it => mapStepWithLifecycle(syncState, it),
        // 2) Overwrites default step from [1].
        it => mapStepWithConf(conf, it)
      )
    ),
    // 3) It must overwrite the step from [2].
    items => setupFirstStep(syncState, items),
    // 4) It needs the actual step.
    array.map(mapStepWithStyle)
  );
}

function mapStepWithLifecycle<I extends CredentialsMainStep>(
  syncState: ReadStatusOutput,
  it: I
): I {
  switch (syncState.status) {
    case "WaitingForConnection":
    case "MobileConnected":
    case "ConnectionLost": {
      return it;
    }
    case "UserIdCreated": {
      switch (it.type) {
        case "UserId": {
          return { ...it, lifecycle: "Created" };
        }
        case "Pin": {
          return { ...it, lifecycle: "InProgress" };
        }
        case "Password": {
          return { ...it, lifecycle: "Required" };
        }
      }
    }
    case "PinCreated": {
      // PinCreated implies the User Id has been created.
      switch (it.type) {
        case "UserId": {
          return { ...it, lifecycle: "Created" };
        }
        case "Pin": {
          return { ...it, lifecycle: "Created" };
        }
        case "Password": {
          return { ...it, lifecycle: "InProgress" };
        }
      }
    }
    case "PasswordCreated": {
      // PasswordCreated implies the User Id and the Pin have been created.
      switch (it.type) {
        case "UserId": {
          return { ...it, lifecycle: "Created" };
        }
        case "Pin": {
          return { ...it, lifecycle: "Created" };
        }
        case "Password": {
          return { ...it, lifecycle: "Created" };
        }
      }
    }
  }
}

function mapStepWithConf<I extends CredentialsMainStep>(
  conf: CredentialsStepsModel,
  it: I
): I {
  // Features are enabled by default.
  // Only an actual false value disables them.
  switch (it.type) {
    case "UserId": {
      return toggleStepFeature(conf.hasUserId, it);
    }
    case "Pin": {
      return toggleStepFeature(conf.hasPin, it);
    }
    case "Password": {
      return toggleStepFeature(conf.hasPassword, it);
    }
  }
}

function toggleStepFeature<I extends CredentialsMainStep>(
  enabled: boolean,
  it: I
): I {
  return {
    ...it,
    lifecycle: enabled ? it.lifecycle : "Disabled",
  };
}

function mapStepWithStyle<I extends CredentialsMainStep>(
  it: I
): I & CredentialsMainStepWithStyle {
  switch (it.lifecycle) {
    case "Disabled": {
      return {
        ...it,
        icon: WarningIcon,
        iconColor: palette.warning600,
        iconMessage: "CreateProfileParent.error.not.applicable",
      };
    }
    case "Required": {
      return {
        ...it,
        icon: ToDoIcon,
        iconColor: palette.neutral500,
        iconMessage: "CreateProfileParent.status.following",
      };
    }
    case "InProgress": {
      return {
        ...it,
        icon: ProgressIcon,
        iconColor: palette.blue600,
        iconMessage: "CreateProfileParent.status.in.progress",
      };
    }
    case "Created": {
      return {
        ...it,
        icon: SuccessIcon,
        iconColor: palette.success800,
        iconMessage: "CreateProfileParent.status.done",
      };
    }
  }
}

function setupFirstStep<I extends CredentialsMainStep>(
  syncState: ReadStatusOutput,
  items: Array<I>
): Array<I> {
  switch (syncState.status) {
    case "WaitingForConnection":
    case "ConnectionLost": {
      // In these cases, no step must be promoted as InProgress.
      return items;
    }
  }

  let isPromotedInProgress = false;

  const nextItems = items.map(it => {
    if (isPromotedInProgress) {
      return it;
    }

    switch (it.lifecycle) {
      case "InProgress":
        // There is already an InProgress step. No step must be promoted as
        // InProgress.
        isPromotedInProgress = true;
        break;
      case "Required":
        // When the the backend signals MobileConnected, the first Required
        // step must be promoted as the one InProgress.
        isPromotedInProgress = true;
        return { ...it, lifecycle: "InProgress" };
    }

    return it;
  });

  return nextItems;
}

export type CredentialsMainStepType = "UserId" | "Pin" | "Password";
export type CredentialsMainStepLifecycle =
  | "Disabled"
  | "Required"
  | "InProgress"
  | "Created";

export interface CredentialsMainStep {
  type: CredentialsMainStepType;
  lifecycle: CredentialsMainStepLifecycle;
  message: LocaleKey;
}

interface CredentialsMainStepWithStyle extends CredentialsMainStep {
  icon: React.ComponentType<any>;
  iconColor: PaletteColor;
  iconMessage: LocaleKey;
}
