import * as t from "io-ts";
import { eq } from "fp-ts";
import { constFalse, constTrue } from "fp-ts/function";
import { FileContent, LocalizedString } from "design-system";
import { apiCall } from "../../APICall";
import { nonEmptyArray } from "io-ts-types/lib/nonEmptyArray";

const PendingActionsInput = t.type({
  checkPendingActions: t.boolean,
});

export type PendingActionsInput = t.TypeOf<typeof PendingActionsInput>;

const PendingActionsOutput = t.type({
  emailVerified: t.boolean,
  crsRelevant: t.boolean,
  fatcaRelevant: t.boolean,
  overdraftAvailability: t.boolean,
  sbRelevant: t.boolean,
});

export type PendingActionsOutput = t.TypeOf<typeof PendingActionsOutput>;

export const pendingActions = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["packages", "uKonto", "pendingActions"],
  inputCodec: PendingActionsInput,
  outputCodec: PendingActionsOutput,
});

export const sendDocumentsCommand = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["packages", "uKonto", "documents", "email"],
  inputCodec: t.void,
});

const UserEmailOutput = t.type({
  email: t.string,
});

export const userEmail = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["clients", "identification", "email"],
  inputCodec: t.void,
  outputCodec: UserEmailOutput,
});

const MobileAppQRCodeInput = t.type({
  component: t.string,
  size: t.number,
});

const MobileAppQRCodeOutput = FileContent;

export const mobileAppQRCode = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["utilities", "configurations", "noauth", "mobileAppQRCode"],
  inputCodec: MobileAppQRCodeInput,
  outputCodec: MobileAppQRCodeOutput,
});

const OnboardingPending = t.type(
  { status: t.literal("PENDING") },
  "OnboardingPending"
);
export type OnboardingPending = t.TypeOf<typeof OnboardingPending>;

const OnboardingFailure = t.type(
  { status: t.literal("ERROR") },
  "OnboardingError"
);
const OnboardingRejected = t.type(
  { status: t.literal("REJECTED") },
  "OnboardingRejected"
);
const OnboardingMicropayment = t.type(
  { status: t.literal("MICROPAYMENT") },
  "OnboardingMicropayment"
);
const OnboardingRedirectToTheBank = t.type(
  { status: t.literal("REDIRECT_TO_THE_BANK") },
  "OnboardingRedirectToTheBank"
);
const OnboardingContinue = t.type(
  { status: t.literal("CONTINUE") },
  "OnboardingContinue"
);
export type OnboardingContinue = t.TypeOf<typeof OnboardingContinue>;

const OnboardingNotifyClientOfDelay = t.type(
  { status: t.literal("NOTIFY_CLIENT_OF_DELAY") },
  "OnboardingNotifyClientOfDelay"
);
const OnboardingNotifyClientOfDelayWithErrors = t.type(
  { status: t.literal("NOTIFY_CLIENT_OF_DELAY_ERRORS") },
  "OnboardingNotifyClientOfDelayWithErrors"
);

const OnboardingSuccess = t.type(
  {
    status: t.literal("DONE"),
    stepsResults: nonEmptyArray(
      t.type(
        {
          type: t.keyof({ SUCCESS: true, ERROR: true }),
          message: LocalizedString,
        },
        "OnboardingStepResult"
      )
    ),
  },
  "OnboardingPending"
);

export type OnboardingSuccess = t.TypeOf<typeof OnboardingSuccess>;

export const OnboardingResultsOutput = t.union([
  OnboardingPending,
  OnboardingFailure,
  OnboardingRejected,
  OnboardingMicropayment,
  OnboardingRedirectToTheBank,
  OnboardingNotifyClientOfDelay,
  OnboardingNotifyClientOfDelayWithErrors,
  OnboardingSuccess,
  OnboardingContinue,
]);

export type OnboardingResultsOutput = t.TypeOf<typeof OnboardingResultsOutput>;

export const onboardingResults = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "authorization", "onboarding", "results"],
  inputCodec: t.void,
  outputCodec: OnboardingResultsOutput,
});
