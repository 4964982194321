import {
  Box,
  Stack,
  Button,
  Heading,
  FileIcon,
  Space,
  InlineLoader,
  convertToBlob,
  downloadFile,
} from "design-system";
import { constFalse, pipe } from "fp-ts/function";
import { array, boolean, option, taskEither } from "fp-ts";
import { DocumentMeta } from "../../Common/documentAPI";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { useFormatMessage } from "../../intl";
import { useCommand } from "../../useAPI";
import * as documentAPI from "../../Common/documentAPI";
import { Option } from "fp-ts/Option";
import { useState } from "react";

type Props = {
  documents: NonEmptyArray<DocumentMeta>;
};

export function Contracts(props: Props) {
  const documentDownload = useCommand(documentAPI.content);

  const [contractDownloading, setContractDownloading] = useState<
    Option<DocumentMeta>
  >(option.none);

  const Document = ({ contract }: { contract: DocumentMeta }) => {
    const formatMessage = useFormatMessage();

    const renderDownloadDocument = () =>
      pipe(
        contractDownloading,
        option.fold(constFalse, ({ docId }) => docId === contract.docId),
        boolean.fold(
          () => (
            <Button
              key={contract.docId}
              label={formatMessage(
                "SummaryAndDownload.Summary.contracts.download.button"
              )}
              variant="text"
              size="default"
              action={pipe(
                taskEither.bracket(
                  taskEither.rightIO(() =>
                    setContractDownloading(option.some(contract))
                  ),
                  () =>
                    pipe(
                      documentDownload({ docId: contract.docId }),
                      taskEither.map(item => {
                        const { content: documentBase64 } = item;
                        downloadFile(
                          convertToBlob(documentBase64, "PDF"),
                          `${contract.docTitle}.pdf`
                        );
                      })
                    ),
                  () =>
                    taskEither.rightIO(() =>
                      setContractDownloading(option.none)
                    )
                )
              )}
            />
          ),
          () => (
            <InlineLoader
              message={formatMessage(
                "SummaryAndDownload.Summary.contracts.download.loading"
              )}
              size="medium"
            />
          )
        )
      );

    return (
      <Box key={contract.docId} vAlignContent="center">
        <Box hAlignContent="left" vAlignContent="center">
          <FileIcon size="medium" />
        </Box>
        <Space units={2} />
        <Box grow>
          <Heading size="x-small" weight="regular">
            {contract.docTitle}
          </Heading>
        </Box>
        {renderDownloadDocument()}
      </Box>
    );
  };

  return (
    <Box column>
      <Stack column units={4}>
        {pipe(
          props.documents,
          array.map(contract => (
            <Document key={contract.docId} contract={contract} />
          ))
        )}
      </Stack>
    </Box>
  );
}
