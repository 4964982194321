import * as t from "io-ts";
import { optionFromUndefined } from "../globalDomain";

export const Profile = t.type({
  userId: optionFromUndefined(t.string),
  pin: optionFromUndefined(t.string),
  password: t.string,
});
export type Profile = t.TypeOf<typeof Profile>;

export const CRMLeadStepUkonto = t.keyof({
  Kyc: true,
  Password: true,
  SecurityPIN: true,
  PersonalDataEdit: true,
});
export type CRMLeadStepUkonto = t.TypeOf<typeof CRMLeadStepUkonto>;

export const CreateProfileInput = t.type({
  profile: Profile,
});
export type CreateProfileInput = t.TypeOf<typeof CreateProfileInput>;

export const NonResidentsAdditionalDocumentsType = t.keyof({
  TEMPORARY_RESIDENCE_PERMIT: true,
  PERMISSION_TO_STUDY: true,
  EMPLOYMENT_CONTRACT_WITH_CLIENT_DATA: true,
  LEASE_AGREEMENT_SIGNED_ON_CLIENT_NAME: true,
  REAL_ESTATE_LAND_REGISTER_EXTRACT: true,
  LONG_TERM_RESIDENCE_PERMIT: true,
  STUDENT_VISA: true,
  MARRIAGE_CERTIFICATE: true,
  BUSINESS_CERTIFICATE_AUTHORITY: true,
  LONG_TERM_VISA_90_PLUS: true,
});

export type NonResidentsAdditionalDocumentsType = t.TypeOf<
  typeof NonResidentsAdditionalDocumentsType
>;
