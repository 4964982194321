import { useAppContext } from "../../../useAppContext";
import { constNull, pipe } from "fp-ts/function";
import * as remoteData from "../../../RemoteData";
import { Loader, Box } from "design-system";
import * as classes from "./DownloadAppButton.treat";
import { Tenant, foldTenant, SupportedLocales } from "../../../globalDomain";
import { taskEither } from "fp-ts";
import { TaskEither } from "fp-ts/TaskEither";
import { useMemo } from "react";
import { useRemoteData } from "../../../useRemoteData";

type Platform = "iOS" | "Android";

export type Props = {
  platform: Platform;
};

function image(className: string): (src: string) => JSX.Element {
  return src => (
    <Box className={className} style={{ backgroundImage: `url(${src})` }} />
  );
}

function storeButton(
  platform: Platform,
  locale: SupportedLocales
): TaskEither<unknown, JSX.Element> {
  const fetchButton = (() => {
    switch (platform) {
      case "iOS": //NOSONAR
        switch (locale) {
          case "en":
            return () => import("./app-store-badge-en.svg");
          case "cs":
            return () => import("./app-store-badge-cz.svg");
          case "sk":
            return () => import("./app-store-badge-sk.svg");
        }
      case "Android":
        switch (locale) {
          case "en":
            return () => import("./google-play-badge-en.svg");
          case "cs":
            return () => import("./google-play-badge-cz.svg");
          case "sk":
            return () => import("./google-play-badge-cz.svg");
        }
    }
  })();

  return pipe(
    taskEither.tryCatch(fetchButton, constNull),
    taskEither.map(img => img.default),
    taskEither.map(image(classes.storeButton))
  );
}

function storeLink(platform: Platform, tenant: Tenant): string {
  switch (platform) {
    case "Android":
      return foldTenant(
        tenant,
        () =>
          "https://play.google.com/store/apps/details?id=hr.asseco.android.jimba.mUCI.sk",
        () =>
          "https://play.google.com/store/apps/details?id=hr.asseco.android.jimba.mUCI.cz"
      );
    case "iOS":
      return foldTenant(
        tenant,
        () => "https://apps.apple.com/sk/app/smart-banking/id441496696",
        () => "https://apps.apple.com/cz/app/smart-banking/id468057018"
      );
  }
}

export function DownloadAppButton(props: Props) {
  const {
    apiParameters: { tenant, language },
  } = useAppContext();

  const fetchStoreButton = useMemo(
    () => storeButton(props.platform, language),
    [props.platform, language]
  );

  const downloadAppbutton = pipe(
    useRemoteData(useMemo(() => fetchStoreButton, [fetchStoreButton])),
    remoteData.fold(
      () => <Loader />,
      constNull,
      button => button
    )
  );

  return (
    <Box className={classes.downloadAppButton} column hAlignContent="center">
      <a
        href={storeLink(props.platform, tenant)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {downloadAppbutton}
      </a>
    </Box>
  );
}
