import { Dialog, ErrorBanner, Loader } from "design-system";
import { useFormatMessage } from "../../intl";
import { pipe } from "fp-ts/function";
import * as remoteData from "../../RemoteData";
import { Contracts } from "./Contracts";
import { option } from "fp-ts";
import { useQuery } from "../../useAPI";
import * as apis from "../api";

type Props = {
  onDismiss: () => unknown;
};

export function PrintDocumentsDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const [documents] = useQuery(apis.signedContractualDocuments);

  const renderDocuments = pipe(
    documents,
    remoteData.fold(
      () => <Loader />,
      () => <ErrorBanner children={formatMessage("GenericError")} />,
      documents => {
        return <Contracts documents={documents} />;
      }
    )
  );

  return (
    <Dialog
      variant="left"
      size="medium"
      title={formatMessage("SummaryAndDownload.Summary.contracts.title")}
      onDismiss={option.some(props.onDismiss)}
      actions={[]}
    >
      {renderDocuments}
    </Dialog>
  );
}
