import { useAuthenticationContext } from "design-system";
import { option, taskEither } from "fp-ts";
import { constUndefined, constVoid, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { UUID } from "io-ts-types/UUID";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  fold as foldRemoteData,
  remoteFailure,
  remoteLoading,
  remoteSuccess,
} from "../../RemoteData";
import { useCommand, usePollingEffect, useQuery } from "../../useAPI";
import { useCountdown } from "../../Common/IdleGuard/useCountdown";
import {
  readLink,
  readParameters,
  ReadParametersQuery,
  readRecipients,
  readStatus,
  ReadStatusOutput,
  sendLink,
  updatePassword,
  updatePin,
  updateUserId,
} from "./api";
import { useUpdateEffect } from "react-use";

const CONNECTION_LOST: ReadStatusOutput["status"] = "ConnectionLost";
const WAITING_FOR_CONNECTION: ReadStatusOutput["status"] =
  "WaitingForConnection";

export function useCredentialsSyncState(
  withCountdown: boolean = false,
  mobileApi: boolean
) {
  const [syncState, setSyncState] = useState<ReadStatusOutput>({
    status: WAITING_FOR_CONNECTION,
  });

  const countdownIsStopped =
    !withCountdown ||
    syncState.status === CONNECTION_LOST ||
    syncState.status === WAITING_FOR_CONNECTION;

  const [, resetCountdown] = useCountdown(
    5 * 60 * 1000,
    () => {
      if (syncState.status !== CONNECTION_LOST) {
        setSyncState({ status: CONNECTION_LOST });
      }
    },
    countdownIsStopped
  );

  const prevStatus = useRef(syncState.status);

  const restartCountdown = (result: ReadStatusOutput) => {
    if (prevStatus.current !== result.status && withCountdown) {
      resetCountdown();
      prevStatus.current = result.status;
    }
  };

  const [pollError, setPollError] = useState(false);

  const restartPolling_ = usePollingEffect(readStatus, {
    intervalMS: 3 * 1000, // 3 seconds.
    shouldPollingContinue() {
      return !mobileApi || syncState.status !== CONNECTION_LOST;
    },
    onError() {
      if (pollError) {
        setSyncState({ status: CONNECTION_LOST });
      }
      setPollError(true);
    },
    onSuccess(result) {
      setPollError(false);
      if (
        mobileApi &&
        syncState.status !== WAITING_FOR_CONNECTION &&
        result.status === WAITING_FOR_CONNECTION
      ) {
        setSyncState({ status: CONNECTION_LOST });
        return;
      }
      if (syncState.status !== CONNECTION_LOST) {
        setSyncState(result);
        restartCountdown(result);
      }
    },
  });

  useUpdateEffect(() => {
    if (syncState.status === WAITING_FOR_CONNECTION && mobileApi === false) {
      restartPolling_();
    }
  }, [syncState.status]);

  const restartPolling = () => {
    if (withCountdown) {
      resetCountdown();
    }
    setSyncState({ status: WAITING_FOR_CONNECTION });
  };

  return { syncState, restartPolling };
}

export function useCredentialsLink() {
  const [linkSent, setLinkSent] = useState(false);
  const [linksQuota, setLinksQuota] = useState<Option<number>>(option.none);

  const commands = {
    sendLink: useCommand(sendLink),
  };

  const onSendLink = useCallback(() => {
    setLinkSent(true);

    pipe(
      commands.sendLink(),
      taskEither.bimap(
        () => setLinkSent(false),
        result => setLinksQuota(option.of(result.attemptsLeft))
      )
    )();
  }, [commands.sendLink]);

  const resetLinkSent = () => {
    setLinkSent(false);
  };

  return { linkSent, linksQuota, onSendLink, resetLinkSent };
}

export function useCredentialsMainApi(withCountdown: boolean) {
  const [phoneNumberQuery] = useQuery(readRecipients, {
    linkPurpose: "addingCredentials",
  });
  const credentialsLink = useCredentialsLink();
  return {
    ...useCredentialsSyncState(
      withCountdown && credentialsLink.linkSent,
      false
    ),
    ...credentialsLink,
    phoneNumberQuery,
  };
}

export function useCredentialsMobileApi(id: UUID) {
  // readLink() and readParameters() queries are not side effect free.
  // Even if they are queries, readParameters() must be run after readLink(),
  // otherwise backend fails.
  // We use a command to ensure ordering, still maintaining the semantic of the
  // queries dev side.
  const flowIdRef = useRef<null | UUID>(null);
  const [linkQuery, refreshLink] = useQuery(readLink, { id });
  const parametersCommand = useCommand(readParameters);
  const [parametersQuery, setParametersQuery] = useState<ReadParametersQuery>(
    remoteLoading
  );
  const { authInfo, login } = useAuthenticationContext();

  useEffect(() => {
    flowIdRef.current = null;
    setParametersQuery(remoteLoading);

    pipe(
      linkQuery,
      foldRemoteData(constVoid, constVoid, (result, loading) => {
        if (loading) {
          // linkQuery is re-fetching, we must wait it to settle.
          return;
        }

        const { flowId } = result;

        flowIdRef.current = flowId;

        pipe(
          authInfo,
          option.fold(
            () => console.error("(CredentialsApi): missing Auth Info."),
            authInfo => login({ ...authInfo, flowId })
          )
        );
      })
    );
  }, [linkQuery]);

  const flowId = pipe(
    authInfo,
    option.fold(constUndefined, authInfo => authInfo.flowId)
  );

  useEffect(() => {
    if (flowId !== flowIdRef.current) {
      return;
    }

    pipe(
      parametersCommand({ id }),
      taskEither.bimap(
        error => void setParametersQuery(remoteFailure(error, false)),
        parameters => void setParametersQuery(remoteSuccess(parameters, false))
      )
    )();
  }, [flowId /*, id */, linkQuery]);
  // NOTE: id must not be a dependency, otherwise readParameters() command
  // is re-executed before a (possibly) fulfilled linkQuery is re-executed.

  return {
    ...useCredentialsSyncState(false, true),
    linkQuery,
    parametersQuery,
    updateUserId: useCommand(updateUserId),
    updatePin: useCommand(updatePin),
    updatePassword: useCommand(updatePassword),
    refreshLink,
  };
}
