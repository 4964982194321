import * as t from "io-ts";
import { eq } from "fp-ts";
import { Eq } from "fp-ts/Eq";
import { apiCall } from "../../APICall";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { constTrue } from "fp-ts/function";

const UserIDSuggestionsOutput = t.array(
  NonEmptyString,
  "UserIDSuggestionsOutput"
);
interface UserIDSuggestionsOutput
  extends t.TypeOf<typeof UserIDSuggestionsOutput> {}

export const userIDSuggestions = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["clients", "profile", "userId", "suggestions"],
  inputCodec: t.void,
  outputCodec: UserIDSuggestionsOutput,
});

const UserIDValidateInput = t.type(
  {
    userId: NonEmptyString,
    ignoreCount: NonEmptyString,
  },
  "UserIDValidateInput"
);
interface UserIDValidateInput extends t.TypeOf<typeof UserIDValidateInput> {}
const eqUserIDValidateInput: Eq<UserIDValidateInput> = eq.getStructEq({
  userId: eq.eqString,
  ignoreCount: eq.eqString,
});

const InvalidUserIdError = t.type({ id: t.literal("InvalidUserId") });
const MaximumInvalidUserIdAttemptsError = t.type({
  id: t.literal("MaximumUserIdAttempts"),
});

export const UserIDValidationError = t.union([
  InvalidUserIdError,
  MaximumInvalidUserIdAttemptsError,
]);

export type UserIDValidationError = t.TypeOf<typeof UserIDValidationError>;

export const validateUserID = apiCall({
  inputEq: eqUserIDValidateInput,
  path: ["clients", "profile", "userId", "validate"],
  inputCodec: UserIDValidateInput,
  errorCodec: UserIDValidationError,
});
