import {
  Banner,
  Body,
  Box,
  Button,
  Children,
  AlertDialog,
  Dialog,
  MobileIcon,
  Space,
  Stack,
} from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { option } from "fp-ts";
import { constant, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useCallback, useState } from "react";
import { useFormatMessage } from "../../intl";
import * as remoteData from "../../RemoteData";
import { ReadRecipientsQuery } from "./api";
import { useTaskDelayed } from "./hooks";
import { IO } from "fp-ts/IO";

export const CredentialsDialogCommonProps = {
  variant: "center",
  size: "medium",
} as const;

export const CredentialsBannerCommonProps = {
  title: option.none,
  actions: option.none,
  onDismiss: option.none,
};

export const CredentialsLinkResendDelay = 60 * 1000; // 1 minute.
// export const CredentialsLinkResendDelay = 3 * 1000; // Enable this in dev mode.

export type CredentialsWaitDialogProps = {
  linksQuota: Option<number>;
  phoneNumberQuery: ReadRecipientsQuery;
  onClose: () => void;
  onSendLink: () => void;
};

export function CredentialsWaitDialog(props: CredentialsWaitDialogProps) {
  const { linksQuota, phoneNumberQuery, onClose, onSendLink } = props;
  const [linkResent, setLinkResent] = useState(false);
  const [linkResentEnabled, setLinkResentEnabled] = useState(true);
  const formatMessage = useFormatMessage();
  const onLinkResendEnabled = useCallback(() => {
    setLinkResentEnabled(true);
  }, []);
  const linkResendEnablerTask = useTaskDelayed(
    CredentialsLinkResendDelay,
    onLinkResendEnabled
  );

  function onResendLinkClick() {
    setLinkResent(true);
    setLinkResentEnabled(false);
    linkResendEnablerTask.start();

    onSendLink();
  }

  return (
    <Dialog
      {...CredentialsDialogCommonProps}
      title={formatMessage(
        "UKonto.CredentialsCreationMobile.Main.waitingTitle"
      )}
      subtitle={formatMessage(
        "UKonto.CredentialsCreationMobile.Main.waitingSubtitle",
        {
          phone: pipe(
            phoneNumberQuery,
            remoteData.fold(
              () => "{loading...}",
              () => "{unknown}",
              result =>
                pipe(
                  result.Client.phoneNumber,
                  option.getOrElse(constant("{unknown}"))
                )
            )
          ),
        }
      )}
      icon={MobileIcon}
      actions={[
        {
          variant: "text",
          label: formatMessage(
            "UKonto.CredentialsCreationMobile.Main.waitingCancelAction"
          ),
          action: onClose,
        },
      ]}
      onDismiss={option.some(onClose)}
    >
      <Stack units={4} column>
        <Banner
          {...CredentialsBannerCommonProps}
          type="informative"
          content={formatMessage(
            "UKonto.CredentialsCreationMobile.Main.waitingNote"
          )}
        />

        {linkResent && (
          <Banner
            {...CredentialsBannerCommonProps}
            type="success"
            content={formatMessage(
              "UKonto.CredentialsCreationMobile.Main.waitingSentNote"
            )}
          />
        )}

        {option.isSome(linksQuota) && linksQuota.value <= 1 && (
          <Banner
            {...CredentialsBannerCommonProps}
            type="warning"
            content={formatMessage(
              linksQuota.value === 0
                ? "UKonto.CredentialsCreationMobile.Main.waitingQuotaEnd"
                : "UKonto.CredentialsCreationMobile.Main.waitingQuotaLast"
            )}
          />
        )}

        {(option.isNone(linksQuota) || linksQuota.value > 0) && (
          <Box hAlignContent="center" vAlignContent="center">
            <Body size="medium" weight="regular" color={palette.neutral500}>
              {formatMessage(
                "UKonto.CredentialsCreationMobile.Main.waitingResendLinkDescription"
              )}
            </Body>

            <Space units={4} />

            <Button
              variant="text"
              size="default"
              label={pipe(
                linksQuota,
                option.fold(
                  () =>
                    formatMessage(
                      "UKonto.CredentialsCreationMobile.Main.waitingResendLinkAction"
                    ),
                  it =>
                    formatMessage(
                      "UKonto.CredentialsCreationMobile.Main.waitingResendLinkRemainingAction",
                      { count: it }
                    )
                )
              )}
              disabled={!linkResentEnabled}
              action={onResendLinkClick}
            />
          </Box>
        )}
      </Stack>
    </Dialog>
  );
}

export type CredentialsConnectedDialogProps = {
  onClose: () => void;
};

export function CredentialsConnectedDialog(
  props: CredentialsConnectedDialogProps
) {
  const { onClose } = props;
  const formatMessage = useFormatMessage();

  return (
    <Dialog
      {...CredentialsDialogCommonProps}
      title={formatMessage(
        "UKonto.CredentialsCreationMobile.Main.connectedTitle"
      )}
      subtitle={formatMessage(
        "UKonto.CredentialsCreationMobile.Main.connectedSubtitle"
      )}
      icon={MobileIcon}
      actions={[
        {
          variant: "primary",
          label: formatMessage(
            "UKonto.CredentialsCreationMobile.Main.connectedAction"
          ),
          action: onClose,
        },
      ]}
      onDismiss={option.some(onClose)}
    />
  );
}

export type CredentialsErrorDialogProps = {
  onClose: () => void;
  onRemoteCredentialsExit: IO<unknown>;
  isSLFlow: boolean;
};

export function CredentialsErrorDialog(props: CredentialsErrorDialogProps) {
  const { onClose, onRemoteCredentialsExit, isSLFlow } = props;
  const formatMessage = useFormatMessage();

  return (
    <Dialog
      {...CredentialsDialogCommonProps}
      title={formatMessage("UKonto.CredentialsCreationMobile.Main.errorTitle")}
      subtitle={formatMessage(
        "UKonto.CredentialsCreationMobile.Main.errorSubtitle"
      )}
      icon={MobileIcon}
      actions={[
        {
          variant: "text",
          label: formatMessage(
            "UKonto.CredentialsCreationMobile.Main.errorCancelAction"
          ),
          action: isSLFlow ? onRemoteCredentialsExit : onClose,
        },
      ]}
      onDismiss={option.some(isSLFlow ? onRemoteCredentialsExit : onClose)}
    />
  );
}

export type CredentialsCloseDialogProps = {
  onCancel: () => void;
  onConfirm: () => void;
};

export function CredentialsCloseDialog(props: CredentialsCloseDialogProps) {
  const { onCancel, onConfirm } = props;
  const formatMessage = useFormatMessage();

  return (
    <AlertDialog
      {...CredentialsDialogCommonProps}
      type="disruptive"
      title={formatMessage("UKonto.CredentialsCreationMobile.Main.closeTitle")}
      message={formatMessage(
        "UKonto.CredentialsCreationMobile.Main.closeSubtitle"
      )}
      cancelLabel={formatMessage(
        "UKonto.CredentialsCreationMobile.Main.closeCancelAction"
      )}
      confirmLabel={formatMessage(
        "UKonto.CredentialsCreationMobile.Main.closeConfirmAction"
      )}
      onCancel={onCancel}
      onDismiss={onCancel}
      onConfirm={onConfirm}
    />
  );
}

export type CredentialsCloseManagerProps = {
  children: (onWillClose: () => void) => Children;
  onClose: () => void;
};

export function CredentialsCloseManager(props: CredentialsCloseManagerProps) {
  const { children, onClose } = props;
  const [shouldAskConfirm, setShouldAskConfirm] = useState(false);

  const onWillClose = useCallback(() => {
    setShouldAskConfirm(true);
  }, []);

  if (shouldAskConfirm) {
    return (
      <CredentialsCloseDialog
        onCancel={() => setShouldAskConfirm(false)}
        onConfirm={onClose}
      />
    );
  }

  return <>{children(onWillClose)}</>;
}
