import { useState } from "react";
import {
  Banner,
  Space,
  Body,
  Stack,
  Heading,
  Box,
  PanelHeaderIcon,
  LoadingStatusProvider,
  Dialog,
  SpinnerIcon,
  useIsTouchScreen,
  LocalizedString,
  ActionButtonGroup,
  Action,
  useIsMobileLayout,
  FeedbackBlock,
} from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { useFormatMessage } from "../../intl";
import { SendContractsViaEmailDialog } from "./SendContractsViaEmailDialog";
import { useAppContext } from "../../useAppContext";
import { PrintDocumentsDialog } from "./PrintDocumentsDialog";
import { array, option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";

type Props = {
  stepNo: number;
  finalChecksComplete: boolean;
  promotion: Option<LocalizedString>;
  heading: {
    title: LocalizedString;
    description: LocalizedString;
  };
};

export function Summary(props: Props) {
  const [isPrintContractsVisible, setIsPrintContractsVisible] = useState(false);
  const [isEmailContractsVisible, setIsEmailContractsVisible] = useState(false);
  const isMobileLayout = useIsMobileLayout();

  const formatMessage = useFormatMessage();

  const {
    config: { externalCommunication },
  } = useAppContext();

  const isTouchScreen = useIsTouchScreen();

  const showPrintContracts = (): void => {
    setIsPrintContractsVisible(true);
  };

  const showEmailContracts = (): void => {
    setIsEmailContractsVisible(true);
  };

  const hidePrintContractsModal = (): void => {
    setIsPrintContractsVisible(false);
  };

  const hideEmailContractsModal = (): void => {
    setIsEmailContractsVisible(false);
  };

  const printDocumentsDialog = props.finalChecksComplete ? (
    <PrintDocumentsDialog onDismiss={hidePrintContractsModal} />
  ) : (
    <Dialog
      size="medium"
      variant="center"
      icon={SpinnerIcon}
      title={formatMessage("SummaryAndDownload.Summary.waiting.title")}
      subtitle={formatMessage("SummaryAndDownload.Summary.waiting.subTitle")}
      onDismiss={option.some(hidePrintContractsModal)}
      actions={[]}
    />
  );

  const renderActionButtonGroup = (
    <ActionButtonGroup
      actions={
        array.compact([
          option.fromPredicate(() => externalCommunication)({
            label: formatMessage(
              "SummaryAndDownload.Summary.email.contracts.label"
            ),
            variant: "secondary",
            action: showEmailContracts,
            disabled: !props.finalChecksComplete,
          }),
          option.fromPredicate(() => !isTouchScreen)({
            label: formatMessage(
              "SummaryAndDownload.Summary.print.contracts.label"
            ),
            variant: "primary",
            action: showPrintContracts,
          }),
        ]) as [Action] | [Action, Action]
      }
    />
  );

  const renderLoadingContracts = (
    <FeedbackBlock
      type="loading"
      size="medium"
      heading={formatMessage("SummaryAndDownload.Summary.waiting.title")}
      subheading={option.some(
        formatMessage("SummaryAndDownload.Summary.waiting.subTitle")
      )}
    />
  );

  return (
    <>
      <Box>
        <Space units={8} />
        <Stack units={4} vAlignContent="center" shrink={isMobileLayout}>
          <PanelHeaderIcon
            variant="active"
            type="number"
            value={props.stepNo}
          />
          <Heading size="small" weight="medium">
            {props.heading.title}
          </Heading>
        </Stack>
      </Box>
      <Space units={5} />
      <Box>
        <Space units={isMobileLayout ? 8 : 21} />
        <Body color={palette.neutral700} size="medium" weight="regular">
          {props.heading.description}
        </Body>
      </Box>
      <Space units={10} />
      <Box column={isMobileLayout}>
        {!isMobileLayout && <Space units={21} />}
        {props.finalChecksComplete
          ? renderActionButtonGroup
          : renderLoadingContracts}
      </Box>
      {pipe(
        props.promotion,
        option.map(promotion => (
          <>
            <Space units={10} />
            <Box>
              <Space units={isMobileLayout ? 6 : 21} />
              <Banner
                type="informative"
                title={option.some(
                  formatMessage("SummaryAndDownload.Summary.promotionTitle")
                )}
                content={promotion}
                actions={option.none}
                onDismiss={option.none}
              />
            </Box>
          </>
        )),
        option.toNullable
      )}
      <LoadingStatusProvider>
        <>
          {isPrintContractsVisible && printDocumentsDialog}
          {isEmailContractsVisible && (
            <SendContractsViaEmailDialog
              onCancel={hideEmailContractsModal}
              onConfirm={hideEmailContractsModal}
            />
          )}
        </>
      </LoadingStatusProvider>
    </>
  );
}
