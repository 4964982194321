import * as t from "io-ts";
import { apiCall } from "../APICall";
import { eq, option } from "fp-ts";
import { Eq } from "fp-ts/Eq";
import { constFalse, constTrue } from "fp-ts/function";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import {
  ContractReadInput,
  ContractReadOutput,
  DocumentsListOutput,
  eqContractReadInput,
  eqMarketingConsentReadInput,
  MarketingConsentReadInput,
  PhoneNumberOutput,
  PreContractualDocumentsListOutput,
  TermsAndConditionsReceivedOutput,
  ForeignSigningConsentReadInput,
  eqForeignSigningConsentReadInput,
} from "../Common/PreContractualDocuments/api";
import { FileContent, LocalizedString } from "design-system";
import {
  eqLinkGenerationInput,
  eqOtpGenerationInput,
  LinkGenerationError,
  LinkGenerationInput,
  LinkGenerationOutput,
  NeedCaptchaOutput,
  OtpGenerationError,
  OtpGenerationInput,
  OTPGenerationOutput,
  OtpVerifyError,
  OtpVerifyInput,
} from "../PhoneAndEmailVerification/api";
import {
  CreateProfileInput,
  CRMLeadStepUkonto,
  NonResidentsAdditionalDocumentsType,
} from "./domain";
import {
  ClientDataInput,
  ClientDataOutput,
  eqClientDataInput,
} from "../IdUpload/api";
import {
  CheckExistingClientInput,
  CheckExistingClientOutput,
  eqCheckExistingClientInput,
} from "../IdUpload/ClientData/api";
import {
  eqWithCoApplicant,
  GenericError,
  optionFromUndefined,
  withCoApplicant,
} from "../globalDomain";
import {
  ApplicationProductType,
  ApplicationStatus,
} from "../Common/ProcessStoreList/api";
import { UKontoPackageType } from "../UKontoFirstPart/state";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { IncomeInfo, TransactionsInfo } from "../KYC/domain";
import {
  Address,
  AddressWrite,
  eqAddressWrite,
  eqPersonalDocumentOfAddress,
  PersonalDocumentProofOfAddress,
} from "../IdUpload/domain";
import { Country, ScannedDocument } from "../UploadDocuments/domain";
import {
  InitVirtualCardResponse,
  PhysicalCardInput,
} from "../VirtualCards/api";

export const uKontoDocuments = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: PreContractualDocumentsListOutput,
  path: ["packages", "uKonto", "documents"],
});

export const signedContractualDocuments = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: DocumentsListOutput,
  path: ["packages", "uKonto", "signedContractualDocumentList"],
});

export const phoneNumber = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: PhoneNumberOutput,
  path: ["clients", "identification", "phoneNumber"],
});

export const contractRead = apiCall({
  inputCodec: ContractReadInput,
  inputEq: eqContractReadInput,
  outputCodec: ContractReadOutput,
  path: ["packages", "uKonto", "markDocumentRead"],
});

export const termsAndConditionsReceived = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: TermsAndConditionsReceivedOutput,
  path: ["packages", "uKonto", "termsAndConditionsReceived"],
});

const KYCStatusPending = t.type(
  { status: t.literal("PENDING") },
  "KYCStatusPending"
);
const KYCStatusDone = t.type({ status: t.literal("DONE") }, "KYCStatusDone");

export const KYCErrorId = t.keyof(
  {
    GenericError: true,
    RiskyClient: true,
    KycAssessmentNotAvailable: true,
    KycAssessmentResultNotAvailable: true,
    ClientNotOpened: true,
  },
  "KYCErrorId"
);
export type KYCErrorId = t.TypeOf<typeof KYCErrorId>;

const KYCStatusError = t.type(
  {
    status: t.literal("ERROR"),
    id: optionFromNullable(KYCErrorId),
    message: optionFromNullable(LocalizedString),
  },
  "KYCStatusError"
);

export const KYCStatusOutput = t.union([
  KYCStatusPending,
  KYCStatusDone,
  KYCStatusError,
]);
export type KYCStatusOutput = t.TypeOf<typeof KYCStatusOutput>;

export const kycStatus = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "knowYourCustomer", "status"],
  inputCodec: t.void,
  outputCodec: KYCStatusOutput,
});

export const marketingConsent = apiCall({
  inputCodec: MarketingConsentReadInput,
  inputEq: eqMarketingConsentReadInput,
  path: ["packages", "uKonto", "marketing", "marketingConsent"],
});

export const foreignSigningConsent = apiCall({
  inputCodec: ForeignSigningConsentReadInput,
  inputEq: eqForeignSigningConsentReadInput,
  path: ["packages", "uKonto", "foreign", "foreignSigningConsent"],
});

export const CheckMarketingConsentOutput = t.type({
  granted: t.boolean,
});
export type CheckMarketingConsentOutput = t.TypeOf<
  typeof CheckMarketingConsentOutput
>;
export const checkMarketingConsent = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: CheckMarketingConsentOutput,
  path: ["packages", "uKonto", "checkMarketingConsent"],
});

export const cancelOverdraftApplication = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["packages", "uKonto", "cancelOverdraftApplication"],
  inputCodec: t.void,
});

const SetStepInput = t.type(
  {
    step: CRMLeadStepUkonto,
  },
  "SetStepInput"
);

interface SetStepInput extends t.TypeOf<typeof SetStepInput> {}

const eqSetStepInput: Eq<SetStepInput> = eq.getStructEq({
  step: eq.eqString,
});

export const setCRMLeadStep = apiCall({
  inputEq: eqSetStepInput,
  path: ["packages", "uKonto", "setStep"],
  inputCodec: SetStepInput,
});

export const otpGeneration = apiCall({
  inputEq: eqOtpGenerationInput,
  path: ["packages", "uKonto", "identification", "otp"],
  inputCodec: OtpGenerationInput,
  outputCodec: OTPGenerationOutput,
  errorCodec: OtpGenerationError,
});

const eqOtpVerifyInput: Eq<OtpVerifyInput> = eqWithCoApplicant(
  eq.getStructEq({
    otp: eq.eqString,
  })
);

export const otpVerify = apiCall({
  inputEq: eqOtpVerifyInput,
  path: ["packages", "uKonto", "identification", "otp", "verify"],
  inputCodec: OtpVerifyInput,
  errorCodec: OtpVerifyError,
});

export const needsCaptcha = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "identification", "needCaptcha"],
  inputCodec: t.void,
  outputCodec: NeedCaptchaOutput,
});

export const linkGenerationNow = apiCall({
  inputEq: eqLinkGenerationInput,
  path: ["packages", "uKonto", "email", "activationLink", "now"],
  inputCodec: LinkGenerationInput,
  outputCodec: LinkGenerationOutput,
  errorCodec: LinkGenerationError,
});

const QRCodeInput = t.type({
  size: t.number,
});

const QRCodeOutput = FileContent;

export const getQRCode = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["packages", "uKonto", "microTransaction", "QRCode"],
  inputCodec: QRCodeInput,
  outputCodec: QRCodeOutput,
});

export const ForeignDocumentType = t.keyof(
  {
    IDCard: true,
    Passport: true,
  },
  "ForeignDocumentType"
);
export type ForeignDocumentType = t.TypeOf<typeof ForeignDocumentType>;

export const CountryDocSelection = t.type(
  {
    country: optionFromUndefined(Country),
    foreignDocumentType: optionFromUndefined(NonEmptyString),
  },
  "CountryDocSelection"
);

export type CountryDocSelection = t.TypeOf<typeof CountryDocSelection>;

export const clientData = apiCall({
  path: ["packages", "uKonto", "identification", "extractData"],
  inputCodec: ClientDataInput,
  inputEq: eqClientDataInput,
  outputCodec: ClientDataOutput,
});

export const checkExistingClientUkonto = apiCall({
  path: ["packages", "uKonto", "client", "checkExisting"],
  inputCodec: CheckExistingClientInput,
  inputEq: eqCheckExistingClientInput,
  outputCodec: CheckExistingClientOutput,
});

export const clientPreparation = apiCall({
  path: ["packages", "uKonto", "clientPreparation"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
});

export const createProfile = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "profile", "submit"],
  inputCodec: CreateProfileInput,
});

export const AccCollectionErrorCode = t.type(
  {
    id: optionFromUndefined(t.string),
    code: t.string,
  },
  "AccCollectionErrorCode"
);

export const OverdraftError = t.union([AccCollectionErrorCode, GenericError]);
export type OverdraftError = t.TypeOf<typeof OverdraftError>;

export const checkOverdraft = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "checkOverdraft"],
  inputCodec: t.void,
  outputCodec: t.type({
    hasOverdraft: t.boolean,
  }),
  errorCodec: OverdraftError,
});

export const checkOverdraftSelected = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "checkOverdraftSelected"],
  inputCodec: t.void,
  outputCodec: t.type({
    hasOverdraft: t.boolean,
  }),
  errorCodec: OverdraftError,
});

export const checkOverdraftEligibility = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "checkOverdraftEligibility"],
  inputCodec: t.void,
  outputCodec: t.type({
    isOver18: t.boolean,
  }),
});

const ExistingClientCheckMinimumAge = t.type({
  underAge: t.boolean,
});
export type ExistingClientCheckMinimumAge = t.TypeOf<
  typeof ExistingClientCheckMinimumAge
>;

const ExistingClientCheckNotes = t.type({
  hasBlockingNotes: t.boolean,
});
export type ExistingClientCheckNotes = t.TypeOf<
  typeof ExistingClientCheckNotes
>;

const ExistingClientPrivateCheck = t.type({
  privateClient: t.boolean,
});
export type ExistingClientPrivateCheck = t.TypeOf<
  typeof ExistingClientPrivateCheck
>;

const ExistingClientSavingsAccountCheck = t.type({
  hasSavingsAccount: t.boolean,
});
export type ExistingClientSavingsAccountCheck = t.TypeOf<
  typeof ExistingClientSavingsAccountCheck
>;

const ExistingClientCurrentAccountsCheck = t.type({
  hasCurrentAccounts: t.boolean,
});
export type ExistingClientCurrentAccountsCheck = t.TypeOf<
  typeof ExistingClientCurrentAccountsCheck
>;

const ExistingClientCheckKyc = t.type({
  kycNeeded: t.boolean,
  highRisk: t.boolean,
  foreign: t.boolean,
});
export type ExistingClientCheckKyc = t.TypeOf<typeof ExistingClientCheckKyc>;

const ExistingClientCheckPhoneAndEmail = t.type({
  phoneNumberNeeded: t.boolean,
  emailAddressNeeded: t.boolean,
});
export type ExistingClientCheckPhoneAndEmail = t.TypeOf<
  typeof ExistingClientCheckPhoneAndEmail
>;

export const existingClientPrivateCheck = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "savingsAccount", "checkPrivateClient"],
  inputCodec: t.void,
  outputCodec: ExistingClientPrivateCheck,
  errorCodec: GenericError,
});

export const CheckExistingSavingsAccountError = t.union([
  AccCollectionErrorCode,
  GenericError,
]);
export type CheckExistingSavingsAccountError = t.TypeOf<
  typeof CheckExistingSavingsAccountError
>;

export const existingClientSavingsAccountCheck = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "savingsAccount", "checkExistingSavingsAccount"],
  inputCodec: t.void,
  outputCodec: ExistingClientSavingsAccountCheck,
  errorCodec: GenericError,
});

export const existingClientCurrentAccountsCheck = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "checkExistingCurrentAccounts"],
  inputCodec: t.void,
  outputCodec: ExistingClientCurrentAccountsCheck,
  errorCodec: GenericError,
});

export const checkExistingClientSavingsAccount = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "savingsAccount", "checkExistingSavingsAccount"],
  inputCodec: t.void,
  outputCodec: ExistingClientSavingsAccountCheck,
  errorCodec: CheckExistingSavingsAccountError,
});
apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "checkExistingCurrentAccounts"],
  inputCodec: t.void,
  outputCodec: ExistingClientCurrentAccountsCheck,
  errorCodec: CheckExistingSavingsAccountError,
});
export const existingClientKYCCheck = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "checkKyc"],
  inputCodec: t.void,
  outputCodec: ExistingClientCheckKyc,
  errorCodec: GenericError,
});

export const existingClientMinimumAgeCheck = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "checkClientAge"],
  inputCodec: t.void,
  outputCodec: ExistingClientCheckMinimumAge,
  errorCodec: GenericError,
});

export const existingClientNotesCheck = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "checkNotes"],
  inputCodec: t.void,
  outputCodec: ExistingClientCheckNotes,
  errorCodec: GenericError,
});

export const existingClientPhoneAndEmailCheck = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "checkPhoneAndEmail"],
  inputCodec: t.void,
  outputCodec: ExistingClientCheckPhoneAndEmail,
  errorCodec: GenericError,
});

export const CheckPrimaryIdEnums = t.keyof({
  SUCCESS: true,
  KYC_FIXED_EZY: true,
  KYC_FIXED_EZY_UPDATE_CONTACTS: true,
  UPDATE_CONTACTS: true,
  KYC_ID_FIXED_EZY: true,
  KYC_ID_FIXED_EZY_UPDATE_CONTACTS: true,
  ID_FIXED_EZY: true,
  ID_FIXED_EZY_UPDATE_CONTACTS: true,
  UPDATE_ID: true,
  UPDATE_ID_CONTACTS: true,
  UPDATE_ID_MISSING_INFORMATION: true,
  UPDATE_ID_OTHER_THAN_AUTH_ID: true,
  CA_SYNCED_SONIA_UPDATE_ID: true,
  CF_SYNCED_SONIA_UPDATE_ID: true,
  CA_SYNCED_SONIA_SUCCESS_UPLOAD_ID: true,
});
export type CheckPrimaryIdEnums = t.TypeOf<typeof CheckPrimaryIdEnums>;

export const existingClientPrimaryIdCheck = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "checkExpiredOrStolen"],
  inputCodec: t.void,
  outputCodec: CheckPrimaryIdEnums,
  errorCodec: GenericError,
});

export const ExistingClientVerificationsStatus = t.keyof({
  SUCCESS: true,
  FAIL: true,
  ERROR: true,
});
export type ExistingClientVerificationsStatus = t.TypeOf<
  typeof ExistingClientVerificationsStatus
>;

export const ExistingClientVerificationsEnum = t.keyof({
  MINIMUM_AGE: true,
  DIGITAL_CLIENT: true,
  SECURITY_PROFILE: true,
  CONTACT_DETAILS: true,
  PRIMARY_ID: true,
  BLOCKING_NOTES: true,
  KYC_HIGH_RISK: true,
  CLIENT_FROM_PRIVATE_SEGMENT: true,
  CLIENT_WITH_SAVING_ACCOUNT: true,
});
export type ExistingClientVerificationsEnum = t.TypeOf<
  typeof ExistingClientVerificationsEnum
>;

const ExistingClientVerificationsInput = t.type({
  requiredVerificationsList: t.array(ExistingClientVerificationsEnum),
});
export type ExistingClientVerificationsInput = t.TypeOf<
  typeof ExistingClientVerificationsInput
>;

export const ExistingClientVerificationsOutput = t.type({
  status: ExistingClientVerificationsStatus,
  failingVerificationsList: optionFromNullable(
    t.array(ExistingClientVerificationsEnum)
  ),
});
export type ExistingClientVerificationsOutput = t.TypeOf<
  typeof ExistingClientVerificationsOutput
>;

export const existingClientVerification = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "existingClientVerifications"],
  inputCodec: ExistingClientVerificationsInput,
  outputCodec: ExistingClientVerificationsOutput,
  errorCodec: GenericError,
});

const ExistingClientBiometricConsent = t.type({
  granted: t.boolean,
});

export const checkBiometricConsent = apiCall({
  path: ["packages", "uKonto", "checkBiometricConsent"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ExistingClientBiometricConsent,
  errorCodec: GenericError,
});

export const emailNext = apiCall({
  path: ["packages", "uKonto", "email", "next"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  errorCodec: GenericError,
});

export const setExistingCustomer = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "setExistingCustomer"],
  inputCodec: t.void,
  outputCodec: t.unknown,
});

export const CAFeSteps = t.keyof(
  {
    FINANCIAL_INTERMEDIATION: true,
    CONTACTS_COLLECTION: true,
    UPLOAD_ID: true,
    PERSONAL_DATA_CONFIRMATION: true,
    ADDITIONAL_ADDRESS: true,
    NON_RESIDENTS_ADDITIONAL_DOCUMENTS: true,
    PERMANENT_ADDRESS: true,
    PROOF_OF_ADDRESS: true,
    VIRTUAL_CARDS: true,
    ADDITIONAL_INFORMATION: true,
    PERSONAL_AND_TAX: true,
    TRANSACTIONS: true,
    MICROPAYMENT: true,
    CLIENT_CREDENTIALS: true,
    USERNAME: true,
    SIGNATURE: true,
    FINAL_PAGE: true,
  },
  "CAFeSteps"
);
export type CAFeSteps = t.TypeOf<typeof CAFeSteps>;

export const SavingsAccountStartInput = t.type({
  feStep: CAFeSteps,
});

export const startSavingsAccount = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "savingsAccount", "start"],
  inputCodec: SavingsAccountStartInput,
  outputCodec: t.unknown,
  errorCodec: GenericError,
});

export const SaveApplicationInput = t.type({
  feStep: optionFromNullable(CAFeSteps),
  status: optionFromNullable(ApplicationStatus),
  incomeInfo: optionFromNullable(IncomeInfo),
  transactionsInfo: optionFromNullable(TransactionsInfo),
});
export type SaveApplicationInput = t.TypeOf<typeof SaveApplicationInput>;

export const saveWithParams = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "saveRestore", "saveWithParams"],
  inputCodec: SaveApplicationInput,
  outputCodec: t.unknown,
});

export const savePhysicalCardValue = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "uKonto",
    "saveRestore",
    "virtualCard",
    "savePhysicalCardValue",
  ],
  inputCodec: PhysicalCardInput,
  outputCodec: t.unknown,
});

export const saveApplication = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "saveRestore", "save"],
  inputCodec: t.void,
  outputCodec: t.unknown,
});

export const deleteApplication = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "saveRestore", "delete"],
  inputCodec: t.void,
  outputCodec: t.unknown,
});

export const rejectApplication = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "saveRestore", "reject"],
  inputCodec: t.void,
  outputCodec: t.unknown,
});

export const closeApplication = apiCall({
  path: ["packages", "uKonto", "saveRestore", "close"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
});

export const DeleteApplicationByIdInput = t.type({
  processId: t.string,
});
export type DeleteApplicationByIdInput = t.TypeOf<
  typeof DeleteApplicationByIdInput
>;

export const deleteApplicationById = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "saveRestore", "deleteById"],
  inputCodec: DeleteApplicationByIdInput,
  outputCodec: t.unknown,
});

export const deleteApplicationByIdWithRestore = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "saveRestore", "deleteByIdWithRestore"],
  inputCodec: DeleteApplicationByIdInput,
  outputCodec: t.unknown,
});

export const DeleteCFApplicationByIdInput = t.type({
  applicationId: t.string,
});
export type DeleteCFApplicationByIdInput = t.TypeOf<
  typeof DeleteCFApplicationByIdInput
>;

export const deleteExistingCFApplication = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "saveRestore", "deleteExistingCFApplication"],
  inputCodec: DeleteCFApplicationByIdInput,
  outputCodec: t.unknown,
});

export const RestoreApplicationInput = t.type({
  applicationId: t.string,
  notStorableResume: t.boolean,
});
export type RestoreApplicationInput = t.TypeOf<typeof RestoreApplicationInput>;

export const ContactsCollectionData = t.type({
  phoneNumber: t.string,
  emailAddress: t.string,
  promoCode: optionFromNullable(t.string),
});
export type ContactsCollectionData = t.TypeOf<typeof ContactsCollectionData>;

export const AccountPackageType = t.keyof({
  BASIC: true,
  MIDDLE: true,
  UPPER: true,
  SAVING: true,
});

export type AccountPackageType = t.TypeOf<typeof AccountPackageType>;

const Profile = t.type({
  alias: optionFromUndefined(t.string),
  pin: optionFromUndefined(t.string),
  password: optionFromUndefined(t.string),
});
type Profile = t.TypeOf<typeof Profile>;

const ProofOfAddressDocs = t.type({
  first: optionFromUndefined(ScannedDocument),
  second: optionFromUndefined(ScannedDocument),
});
type ProofOfAddressDocs = t.TypeOf<typeof ProofOfAddressDocs>;

export const RestoreApplicationData = t.type({
  feStep: CAFeSteps,
  packageType: AccountPackageType,
  requestDate: t.number,
  applicationId: t.string,
  existingClient: t.boolean,
  extractDataResult: optionFromNullable(ClientDataOutput),
  contactsCollectionData: optionFromNullable(ContactsCollectionData),
  documentsAlreadyUploaded: t.boolean,
  biometricConsent: optionFromNullable(t.boolean),
  restartDocumentsUpload: t.boolean,
  profile: optionFromNullable(Profile),
  overdraftRequested: optionFromNullable(t.boolean),
  srbiConsent: optionFromNullable(t.boolean),
  nrkiConsent: optionFromNullable(t.boolean),
  incomeInfo: optionFromNullable(IncomeInfo),
  transactionsInfo: optionFromNullable(TransactionsInfo),
  currentApplicationId: optionFromNullable(t.string),
  reservedAccountNumber: optionFromNullable(t.string),
  clientNumber: optionFromNullable(t.string),
  foreignClient: t.boolean,
  permanentAddress: optionFromUndefined(Address),
  proofOfAddress: optionFromUndefined(PersonalDocumentProofOfAddress),
  proofOfAddressDocs: optionFromUndefined(ProofOfAddressDocs),
  countryDocSelection: optionFromUndefined(CountryDocSelection),
  residency: optionFromUndefined(t.boolean),
  hasAdditionalDocuments: optionFromUndefined(t.boolean),
  physicalCardChecked: optionFromNullable(t.boolean),
});
export type RestoreApplicationData = t.TypeOf<typeof RestoreApplicationData>;

export const ParallelFlowParameters = t.type({
  hasCFFlowInProgress: t.boolean,
  hasCFFlowSentToBO: t.boolean,
  hasCAFlowSentToBO: t.boolean,
  date: optionFromUndefined(t.number),
  existingApplicationId: optionFromUndefined(t.string),
});
export type ParallelFlowParameters = t.TypeOf<typeof ParallelFlowParameters>;

export const RestoreApplicationOutput = t.type({
  restoreApplicationData: optionFromNullable(RestoreApplicationData),
  parallelFlow: optionFromUndefined(ParallelFlowParameters),
  cardProviderChange: optionFromUndefined(t.boolean),
});
export type RestoreApplicationOutput = t.TypeOf<
  typeof RestoreApplicationOutput
>;

export const RestoreErrorKeys = t.keyof(
  {
    TOO_EARLY: true,
  },
  "RestoreErrorKeys"
);
export type RestoreErrorKeys = t.TypeOf<typeof RestoreErrorKeys>;

export const RestoreError = t.type(
  {
    status: RestoreErrorKeys,
  },
  "RestoreError"
);
export type RestoreError = t.TypeOf<typeof RestoreError>;

export const restoreApplication = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "saveRestore", "restore"],
  inputCodec: RestoreApplicationInput,
  outputCodec: RestoreApplicationOutput,
  errorCodec: RestoreError,
});

export const restorePersonalData = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "saveRestore", "restorePersonalData"],
  inputCodec: t.void,
  outputCodec: ClientDataOutput,
});

export const GetNewestInProgressInput = t.type({
  productType: ApplicationProductType,
});
export type GetNewestInProgressInput = t.TypeOf<
  typeof GetNewestInProgressInput
>;

export const getNewestInProgress = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "saveRestore", "getNewestInProgress"],
  inputCodec: GetNewestInProgressInput,
  outputCodec: RestoreApplicationOutput,
});

export const getNewestInProgressExcludeCurrentApp = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "uKonto",
    "saveRestore",
    "getNewestInProgressExcludeCurrentApp",
  ],
  inputCodec: GetNewestInProgressInput,
  outputCodec: RestoreApplicationOutput,
});

export const getNewestInProgressByBirthNumber = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "uKonto",
    "saveRestore",
    "getNewestInProgressByBirthNumber",
  ],
  inputCodec: GetNewestInProgressInput,
  outputCodec: RestoreApplicationOutput,
});

export const GetCurrentPackageTypeOutput = t.type({
  packageType: optionFromNullable(UKontoPackageType),
});
export type GetCurrentPackageTypeOutput = t.TypeOf<
  typeof GetCurrentPackageTypeOutput
>;

export const getCurrentPackageType = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "noauth", "getPackageType"],
  inputCodec: t.void,
  outputCodec: GetCurrentPackageTypeOutput,
});

export const SaveProfileInput = t.type({
  userId: optionFromNullable(NonEmptyString),
  securityPin: optionFromNullable(NonEmptyString),
});
export type SaveProfileInput = t.TypeOf<typeof SaveProfileInput>;

export const saveProfile = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "saveRestore", "saveProfile"],
  inputCodec: SaveProfileInput,
  outputCodec: t.unknown,
});

export const CheckApplicationOutput = t.type({
  productType: optionFromNullable(ApplicationProductType),
  feStep: optionFromNullable(CAFeSteps),
  processId: optionFromNullable(t.string),
});
export type CheckApplicationOutput = t.TypeOf<typeof CheckApplicationOutput>;

export const checkApplication = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "saveRestore", "checkApplication"],
  inputCodec: t.void,
  outputCodec: CheckApplicationOutput,
});

export const resetUpload = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "identification", "resetUpload"],
  inputCodec: t.void,
  outputCodec: t.unknown,
});

const ApplicationLockedInput = t.type(
  {
    applicationId: t.string,
  },
  "ApplicationLockedInput"
);

export const ApplicationLockedOutput = t.type({
  locked: t.boolean,
});
export type ApplicationLockedOutput = t.TypeOf<typeof ApplicationLockedOutput>;

export const isApplicationLocked = apiCall({
  path: ["packages", "uKonto", "saveRestore", "isApplicationLocked"],
  inputCodec: ApplicationLockedInput,
  inputEq: eq.getStructEq({ applicationId: eq.eqString }),
  outputCodec: ApplicationLockedOutput,
});

const SignatureType = t.keyof({
  OtpSms: true,
  Click2Sign: true,
});

export type SignatureType = t.TypeOf<typeof SignatureType>;

export const GetSignatureTypeOutput = t.type({
  signatureType: optionFromNullable(SignatureType),
});
export type GetSignatureTypeOutput = t.TypeOf<typeof GetSignatureTypeOutput>;

export const getSignatureType = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "identification", "getSignatureType"],
  inputCodec: t.void,
  outputCodec: GetSignatureTypeOutput,
});

export const GetForeignClientOutput = t.union([
  t.type({
    fromEu: t.boolean,
    residency: t.boolean,
    citizenship: NonEmptyString,
    foreign: t.literal(true),
  }),
  t.type({
    foreign: t.literal(false),
  }),
]);
export type GetForeignClientOutput = t.TypeOf<typeof GetForeignClientOutput>;
export const getForeignClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "foreign", "client"],
  inputCodec: t.void,
  outputCodec: GetForeignClientOutput,
});

export const ContractType = t.keyof({
  FRAMEWORK_AGREEMENT: true,
  PRODUCT_CONTRACT: true,
});

export type ContractType = t.TypeOf<typeof ContractType>;

export const GetContentOutput = t.type({
  pdfContent: FileContent,
  pdfName: LocalizedString,
});

export type GetContentOutput = t.TypeOf<typeof GetContentOutput>;

const GetContentInput = t.type({
  contractType: ContractType,
});

export type GetContentInput = t.TypeOf<typeof GetContentInput>;

export const getForeignContent = apiCall({
  inputCodec: GetContentInput,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "foreign", "getContent"],
  outputCodec: GetContentOutput,
});

export const PermanentAddressSubmitInput = withCoApplicant(
  t.type(
    {
      permanentAddress: AddressWrite,
    },
    "PermanentAddressSubmitInput"
  )
);
export interface PermanentAddressSubmitInput
  extends t.TypeOf<typeof PermanentAddressSubmitInput> {}
export const eqPermanentAddressSubmitInput: Eq<PermanentAddressSubmitInput> = eqWithCoApplicant(
  eq.getStructEq({
    permanentAddress: eqAddressWrite,
  })
);
export const permanentAddressSubmit = apiCall({
  inputEq: eqPermanentAddressSubmitInput,
  path: ["packages", "uKonto", "identification", "permanentAddress", "submit"],
  inputCodec: PermanentAddressSubmitInput,
});

export const ProofOfAddressSubmitInput = withCoApplicant(
  t.type(
    {
      proofOfAddress: PersonalDocumentProofOfAddress,
    },
    "ProofOfAddressSubmitInput"
  )
);
export interface ProofOfAddressSubmitInput
  extends t.TypeOf<typeof ProofOfAddressSubmitInput> {}
const eqProofOfAddressSubmitInput: Eq<ProofOfAddressSubmitInput> = eqWithCoApplicant(
  eq.getStructEq({
    proofOfAddress: eqPersonalDocumentOfAddress,
  })
);

export const ProofOfAddressSubmitError = t.strict({
  error: LocalizedString,
});
export type ProofOfAddressSubmitError = t.TypeOf<
  typeof ProofOfAddressSubmitError
>;
export const proofOfAddressSubmit = apiCall({
  path: ["packages", "uKonto", "identification", "proofOfAddress", "submit"],
  inputCodec: ProofOfAddressSubmitInput,
  inputEq: eqProofOfAddressSubmitInput,
  outputCodec: t.unknown,
  errorCodec: ProofOfAddressSubmitError,
});

const CountryDocSelectionInput = t.type(
  {
    country: optionFromUndefined(Country),
    foreignDocumentType: optionFromUndefined(ForeignDocumentType),
  },
  "CountryDocSelection"
);

type CountryDocSelectionInput = t.TypeOf<typeof CountryDocSelectionInput>;

export const saveCountryDocSelection = apiCall({
  path: ["packages", "uKonto", "identification", "saveCountryDocSelection"],
  inputCodec: CountryDocSelectionInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export const AdditionalRequiredDocument = t.type({
  documentKey: optionFromNullable(NonResidentsAdditionalDocumentsType),
  documentKeys: optionFromNullable(
    t.array(NonResidentsAdditionalDocumentsType)
  ),
  uploaded: t.boolean,
});

export type AdditionalRequiredDocument = t.TypeOf<
  typeof AdditionalRequiredDocument
>;

export const GetAdditionalRequiredDocumentsInput = t.type({
  requiredKeys: optionFromNullable(
    t.array(NonResidentsAdditionalDocumentsType)
  ),
});

export type GetAdditionalRequiredDocumentsInput = t.TypeOf<
  typeof GetAdditionalRequiredDocumentsInput
>;

export const GetAdditionalRequiredDocumentsOutput = t.type({
  additionalRequiredDocuments: optionFromUndefined(
    t.array(AdditionalRequiredDocument)
  ),
});

export type GetAdditionalRequiredDocumentsOutput = t.TypeOf<
  typeof GetAdditionalRequiredDocumentsOutput
>;

const eqAdditionalRequiredDocumentsInput: Eq<GetAdditionalRequiredDocumentsInput> = eq.getStructEq(
  {
    requiredKeys: option.getEq(eq.eqStrict),
  }
);

export const getAdditionalRequiredDocuments = apiCall({
  inputCodec: GetAdditionalRequiredDocumentsInput,
  inputEq: eqAdditionalRequiredDocumentsInput,
  path: [
    "packages",
    "uKonto",
    "foreign",
    "nonresident",
    "additionalRequiredDocuments",
  ],
  outputCodec: GetAdditionalRequiredDocumentsOutput,
});

export const CheckAllDocsUploadedInput = t.type({
  requiredKeys: t.array(NonResidentsAdditionalDocumentsType),
});

export type CheckAllDocsUploadedInput = t.TypeOf<
  typeof CheckAllDocsUploadedInput
>;

export const CheckAllDocsUploadedOutput = t.type({
  allDocsUploaded: t.boolean,
});

export type CheckAllDocsUploadedOutput = t.TypeOf<
  typeof CheckAllDocsUploadedOutput
>;

const eqCheckAllDocsUploadedInput: Eq<CheckAllDocsUploadedInput> = eq.getStructEq(
  {
    requiredKeys: eq.eqStrict,
  }
);

export const checkAllDocsUploaded = apiCall({
  inputCodec: CheckAllDocsUploadedInput,
  inputEq: eqCheckAllDocsUploadedInput,
  path: [
    "packages",
    "uKonto",
    "foreign",
    "nonresident",
    "checkAllDocsUploaded",
  ],
  outputCodec: CheckAllDocsUploadedOutput,
});

export const getPackageType = apiCall({
  path: ["packages", "uKonto", "noauth", "getPackageType"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: InitVirtualCardResponse,
});

export const transitionToMasterCard = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "uKonto", "transitionToMasterCard"],
  inputCodec: t.void,
  outputCodec: t.unknown,
});
