import { pipe } from "fp-ts/function";
import { option, taskEither } from "fp-ts";
import { Box, Dialog, ErrorIcon, Loader, LocalizedString } from "design-system";
import { useMemo } from "react";
import { useCommand } from "../../useAPI";
import { FilePDFDialog } from "../../Common/Dialogs/FilePDFDialog/FilePDFDialog";
import * as ukontoApi from "../api";
import { DocumentContentOutput } from "../../Common/documentAPI";
import { useRemoteData } from "../../useRemoteData";
import * as remoteData from "../../RemoteData";
import { useFormatMessage } from "../../intl";
import * as classes from "../../3PLanding/LoadingDialog.treat";

type Props = {
  onDismiss: () => unknown;
  title: LocalizedString;
  contractType: ukontoApi.ContractType;
};

export function ForeignDocumentDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const getForeignContent = useCommand(ukontoApi.getForeignContent);

  const foreignContent = useRemoteData(
    useMemo(() => {
      return getForeignContent({ contractType: props.contractType });
    }, [props.title, props.contractType])
  );

  const renderLoading = ((): JSX.Element => {
    return (
      <Dialog
        variant="left"
        size="medium"
        title={props.title}
        actions={[]}
        onDismiss={option.none}
      >
        <Box
          hAlignContent="center"
          vAlignContent="center"
          className={classes.loadingWrapper}
        >
          <Loader />
        </Box>
      </Dialog>
    );
  })();

  const renderError = ((): JSX.Element => {
    return (
      <Dialog
        size="small"
        variant="center"
        icon={ErrorIcon}
        title={formatMessage("GenericError")}
        onDismiss={option.some(props.onDismiss)}
        actions={[]}
      />
    );
  })();

  return pipe(
    foreignContent,
    remoteData.fold(
      () => renderLoading,
      () => renderError,
      content => (
        <FilePDFDialog
          title={content.pdfName}
          onDismiss={props.onDismiss}
          file={taskEither.fromIO<unknown, DocumentContentOutput>(() => ({
            filename: content.pdfName,
            content: content.pdfContent,
            downloadUrl: option.none,
          }))}
        />
      )
    )
  );
}
