import { useEffect, useState } from "react";
import {
  Banner,
  Box,
  DropdownField,
  fieldIssues,
  Form,
  FormSection,
  Heading,
  Issues,
  LocalizedString,
  Space,
  TextField,
  UnorderedList,
  unsafeLocalizedString,
  useForm,
  useIsMobileLayout,
  useIsTouchScreen,
  useStableEquality,
  Validator,
  validators,
} from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { useFormatMessage } from "../../../intl";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { constant, pipe } from "fp-ts/function";
import { array, eq, option, taskEither } from "fp-ts";
import { useCommand, useQuery } from "../../../useAPI";
import * as profileAPI from "../api";
import * as remoteData from "../../../RemoteData";
import { sequenceS } from "fp-ts/Apply";

import { useAppContext } from "../../../useAppContext";
import { NextButton } from "../../../Common/NextButton";
import { useValidators } from "../../../Common/useValidators";
import { TaskEither } from "fp-ts/TaskEither";
import { PersonalDataProcessingDisclaimer } from "../../../Common/PersonalDataProcessingDisclaimer/PersonalDataProcessingDisclaimer";
import { useVirtualFormSubmit } from "../../../Common/VirtualKeyboard/virtualKeyboardUtils";
import { usePropagateHasChanged } from "../../../ClientProfile/usePropagateHasChanged";

type Props = {
  initialUserId: Option<NonEmptyString>;
  onNext: (userId: NonEmptyString) => TaskEither<unknown, unknown>;
  disabled?: boolean;
  onHasChanged?: (hasChanged: boolean) => unknown;
};

type UserIdDropdownOption = {
  value: string;
  label: LocalizedString;
};

export function UserID(props: Props) {
  const formatMessage = useFormatMessage();
  const isTouchScreen = useIsTouchScreen();
  const isMobileLayout = useIsMobileLayout();
  const [isDropDown, setIsDropdown] = useState<boolean>(false);

  const {
    config: { enhancedMF20 },
  } = useAppContext();

  const validateUserId = useCommand(profileAPI.validateUserID, {
    skipTracking: false,
  });

  const { nonBlankString, maxLength, definedNoExtract } = useValidators();

  const onSubmitR5 = ({
    userId,
    userIdFromDropdown,
  }: {
    userId: NonEmptyString;
    userIdFromDropdown: Option<UserIdDropdownOption>;
  }): TaskEither<unknown, unknown> => {
    if (isDropDown) {
      pipe(
        props.onHasChanged,
        option.fromNullable,
        option.map(callback => callback(true))
      );
      let userIdFromDropDown = pipe(
        userIdFromDropdown,
        option.getOrElse(
          () =>
            ({
              value: "",
              label: "",
            } as UserIdDropdownOption)
        )
      );
      //return props.onNext(userIdFromDropDown.value as NonEmptyString);
      return pipe(
        validateUserIdText(userIdFromDropDown.value as NonEmptyString),
        taskEither.chain(() =>
          props.onNext(userIdFromDropDown.value as NonEmptyString)
        )
      );
    }
    return pipe(
      validateUserIdText(userId),
      taskEither.chain(() => props.onNext(userId))
    );
  };

  const requiredDropdownValue = (
    validate: boolean
  ): Validator<Option<UserIdDropdownOption>, Option<UserIdDropdownOption>> =>
    validators.foldPredicate(
      constant(validate),
      constant(values.userIdFromDropdown),
      definedNoExtract()
    );

  const validateUserIdText = (userId: NonEmptyString) =>
    pipe(
      validateUserId({
        userId,
        ignoreCount: isDropDown
          ? ("true" as NonEmptyString)
          : ("false" as NonEmptyString),
      }),
      taskEither.map(userId => {
        pipe(
          props.onHasChanged,
          option.fromNullable,
          option.map(callback => callback(true))
        );
        return userId as NonEmptyString;
      }),
      taskEither.mapLeft(
        (e): Issues => {
          switch (e.id) {
            case "InvalidUserId": {
              isDropDown
                ? (fieldErrors.userIdFromDropdown = option.some([
                    fieldIssues.error(
                      formatMessage(
                        "CreateProfile.UserID.userIdNotValidAnymoreErrorMessage"
                      )
                    ),
                  ]))
                : (fieldErrors.userId = option.some([
                    fieldIssues.error(
                      formatMessage(
                        "CreateProfile.UserID.apiValidationErrorMessage"
                      )
                    ),
                  ]));
              return [
                fieldIssues.error(
                  formatMessage(
                    "CreateProfile.UserID.apiValidationErrorMessage"
                  )
                ),
              ];
            }
            case "MaximumUserIdAttempts": {
              setIsDropdown(true);
              return [
                fieldIssues.error(
                  formatMessage(
                    "CreateProfile.UserID.maximumInvalidUserIdAttemptsErrorMessage"
                  )
                ),
              ];
            }
            case "GenericError": {
              isDropDown
                ? (fieldErrors.userIdFromDropdown = option.some([
                    fieldIssues.error(
                      formatMessage("CreateProfile.UserID.genericError")
                    ),
                  ]))
                : (fieldErrors.userId = option.some([
                    fieldIssues.error(
                      formatMessage("CreateProfile.UserID.genericError")
                    ),
                  ]));
              return [
                fieldIssues.error(
                  formatMessage("CreateProfile.UserID.genericError")
                ),
              ];
            }
          }
        }
      )
    );

  const { fieldProps, handleSubmit, values, fieldErrors, setValues } = useForm(
    {
      initialValues: {
        userId: pipe(
          props.initialUserId,
          option.getOrElse(() => "")
        ),
        userIdFromDropdown: option.none as Option<UserIdDropdownOption>,
      },
      fieldValidators: () => {
        return {
          userId: validators.inSequence(
            nonBlankString,
            validators.minLength(
              6,
              formatMessage("CreateProfile.UserID.feValidationErrorMessage")
            ),
            maxLength(254)
          ),
          userIdFromDropdown: requiredDropdownValue(isDropDown),
        };
      },
    },
    {
      onSubmit: ({ userId, userIdFromDropdown }) =>
        onSubmitR5({ userId, userIdFromDropdown }),
    }
  );

  useVirtualFormSubmit(handleSubmit);

  const userIdFieldProps = fieldProps("userId");

  usePropagateHasChanged<string>({
    initialValue: pipe(props.initialUserId, option.getOrElse(constant(""))),
    fieldProps: userIdFieldProps,
    equality: eq.eqString,
    onHasChanged: props.onHasChanged,
  });

  const [suggestions] = useQuery(profileAPI.userIDSuggestions);
  const suggestedUserIds = pipe(
    suggestions,
    remoteData.fold(constant(option.none), constant(option.none), option.some)
  );

  const suggestedUserIdOptions = pipe(
    suggestedUserIds,
    option.getOrElse(() => [] as NonEmptyString[])
  );
  const firstSuggestion = pipe(
    option.fromNullable(suggestedUserIdOptions[0]),
    option.getOrElse(() => "")
  );
  useEffect(() => {
    if (firstSuggestion && !values.userId) {
      setValues({ userId: firstSuggestion });
    }
  }, [firstSuggestion]);

  const dropDownOptions = suggestedUserIdOptions.map(v => ({
    value: v,
    label: unsafeLocalizedString(v),
  }));

  const [currentSuggestedIDIndex, setCurrentSuggestedIDIndex] = useState<
    Option<number>
  >(option.none);
  const currentSuggestedID = pipe(
    sequenceS(option.option)({ suggestedUserIds, currentSuggestedIDIndex }),
    option.chain(({ suggestedUserIds, currentSuggestedIDIndex }) =>
      array.lookup(currentSuggestedIDIndex % suggestedUserIds.length)(
        suggestedUserIds
      )
    )
  );

  const [currentSuggestedIDStable] = useStableEquality(
    option.getEq(eq.eqString),
    currentSuggestedID
  );

  useEffect(() => {
    pipe(
      currentSuggestedID,
      option.map(suggestedId => userIdFieldProps.onChange(suggestedId))
    );
  }, [currentSuggestedIDStable]);

  return (
    <Box column>
      <Heading size="x-small" weight="medium" color={palette.neutral700}>
        {formatMessage("CreateProfile.UserID.description1")}
      </Heading>
      <Space units={14} />
      <Form>
        <FormSection>
          <>
            {!isDropDown && (
              <TextField
                {...userIdFieldProps}
                label={formatMessage("CreateProfile.UserID.label")}
                placeholder={formatMessage("CreateProfile.UserID.placeholder")}
                disabled={props.disabled}
                cta={
                  enhancedMF20
                    ? {
                        action: () =>
                          pipe(
                            currentSuggestedIDIndex,
                            option.map(index => index + 1),
                            option.alt(() => option.some(1)),
                            setCurrentSuggestedIDIndex
                          ),
                        label: formatMessage(
                          "CreateProfile.UserID.generateLabel"
                        ),
                      }
                    : undefined
                }
              />
            )}
            {isDropDown && (
              <>
                <Banner
                  type="informative"
                  title={option.none}
                  actions={option.none}
                  onDismiss={option.none}
                  content={formatMessage(
                    "CreateProfile.UserID.maximumInvalidUserIdAttemptsErrorMessage"
                  )}
                />
                <Space units={8} />
                <DropdownField
                  options={dropDownOptions}
                  {...fieldProps("userIdFromDropdown")}
                  value={fieldProps("userIdFromDropdown").value}
                  onChange={newVal =>
                    fieldProps("userIdFromDropdown").onChange(
                      pipe(
                        newVal,
                        option.map(value =>
                          pipe(
                            dropDownOptions,
                            array.findFirst(p => p.value === value.value)
                          )
                        ),
                        option.flatten
                      )
                    )
                  }
                  label={formatMessage(
                    "CreateProfile.UserID.suggestedUserId.label"
                  )}
                  placeholder={formatMessage(
                    "CreateProfile.UserID.suggestedUserId.placeholder"
                  )}
                  searchable
                  clearable={false}
                  cancelLabel={formatMessage("Cancel")}
                  noResultsLabel={formatMessage("NoResultsFound")}
                  clearSelectionLabel={formatMessage("Dropdown.clearSelection")}
                  filterInputPlaceholder={formatMessage(
                    "Dropdown.filterInputPlaceholder"
                  )}
                  isMulti={false}
                  disabled={props.disabled}
                />{" "}
              </>
            )}
          </>
        </FormSection>
      </Form>
      <Space units={4} />
      <UnorderedList size="small" listStyle="bullet">
        {[
          formatMessage("CreateProfile.UserID.suggestion1"),
          formatMessage("CreateProfile.UserID.suggestion2"),
          formatMessage("CreateProfile.UserID.suggestion3"),
        ]}
      </UnorderedList>
      <Space units={10} />
      {isTouchScreen && (
        <PersonalDataProcessingDisclaimer
          label={formatMessage(
            "CreateProfile.securityPassword.personalData.agreement"
          )}
        />
      )}
      <Space units={10} />
      <Box
        hAlignContent={isMobileLayout ? undefined : "right"}
        width={isMobileLayout ? "100%" : undefined}
        column={isMobileLayout}
      >
        <NextButton action={handleSubmit} disabled={props.disabled} />
      </Box>
    </Box>
  );
}
