import {
  Box,
  PanelHeaderIcon,
  Stack,
  Space,
  Heading,
  UnorderedList,
  Loader,
  QRCode,
  useIsMobileLayout,
} from "design-system";
import { RuntimeLocaleKey, useFormatMessage } from "../../intl";
import { DownloadAppButton } from "./DownloadAppButton/DownloadAppButton";
import * as classes from "./SummaryAndDownloadCommonStyles.treat";
import { useQuery } from "../../useAPI";
import { mobileAppQRCode } from "./api";
import * as remoteData from "../../RemoteData";
import { constNull, pipe } from "fp-ts/function";
import { spaceUnit } from "design-system/lib/styleConstants";

type Props = {
  stepNo: number;
  isCf: boolean;
};

function QRCodeImage() {
  const [qrCode] = useQuery(mobileAppQRCode, {
    component: "eShopFE",
    size: 150,
  });

  return pipe(
    qrCode,
    remoteData.fold(
      () => <Loader />,
      constNull,
      qrCode => <QRCode code={qrCode} size={150} />
    )
  );
}

export function Download(props: Props) {
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();

  const translationPlaceholder = props.isCf ? ".CF" : "";

  return (
    <Box column shrink className={classes.wrapper}>
      <Stack units={4} vAlignContent="center">
        <PanelHeaderIcon variant="active" type="number" value={props.stepNo} />
        <Heading size="small" weight="medium">
          {formatMessage(
            `SummaryAndDownload${translationPlaceholder}.Download.title` as RuntimeLocaleKey
          )}
        </Heading>
      </Stack>
      <Space units={9} />
      <Box hAlignContent={isMobileLayout ? "center" : undefined}>
        {!isMobileLayout && <Space units={11} />}
        <Stack column={isMobileLayout} units={3}>
          <Box style={{ padding: 2 * spaceUnit }} column hAlignContent="center">
            {QRCodeImage()}
          </Box>
          {!isMobileLayout && <Space units={9} />}
          <Box column shrink>
            <DownloadAppButton platform="iOS" />
            <DownloadAppButton platform="Android" />
          </Box>
          {!isMobileLayout && <Space units={12} />}
        </Stack>
      </Box>
      <Space units={8} />
      <Box>
        {!isMobileLayout && <Space units={12} />}
        <Heading size="x-small" weight="medium">
          {formatMessage(
            `SummaryAndDownload${translationPlaceholder}.Download.ListTitle` as RuntimeLocaleKey
          )}
        </Heading>
      </Box>
      <Space units={4} />
      <Box shrink grow>
        {!isMobileLayout && <Space units={10} />}
        <UnorderedList size="large" listStyle="bullet">
          {[
            formatMessage(
              `SummaryAndDownload${translationPlaceholder}.Download.step1` as RuntimeLocaleKey
            ),
            formatMessage(
              `SummaryAndDownload${translationPlaceholder}.Download.step2` as RuntimeLocaleKey
            ),
            formatMessage(
              `SummaryAndDownload${translationPlaceholder}.Download.step3` as RuntimeLocaleKey
            ),
          ]}
        </UnorderedList>
      </Box>
    </Box>
  );
}
