import * as t from "io-ts";
import { eq } from "fp-ts";
import { constFalse, constTrue } from "fp-ts/function";
import { apiCall } from "../../APICall";
import { UKontoPackageType } from "../state";
import { LocalizedString } from "design-system";
import {
  ChoosePackageTypeInput,
  GetListError,
  GetReservedAccountResponse,
  PackageListResponse,
} from "../../PackagesSelection/api";

export const packagesList = apiCall({
  path: ["packages", "uKonto", "list"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: PackageListResponse,
  errorCodec: GetListError,
});

export const getReservedAccount = apiCall({
  path: ["packages", "uKonto", "getReservedAccount"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: GetReservedAccountResponse,
  errorCodec: GetListError,
});

export const choosePackageType = apiCall({
  path: ["packages", "uKonto", "type"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: ChoosePackageTypeInput,
  outputCodec: t.unknown,
});

export const FinancialIntermediationActText = apiCall({
  path: ["packages", "uKonto", "financialIntermediationAct", "text"],
  inputEq: eq.fromEquals(constTrue),
  inputCodec: t.type({
    type: UKontoPackageType,
  }),
  outputCodec: LocalizedString,
});

export const FinancialIntermediationActSubmit = apiCall({
  path: ["packages", "uKonto", "financialIntermediationAct", "submit"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: t.unknown,
});

export const getFeatureWhitelist = apiCall({
  path: ["utilities", "configurations", "whitelist", "feature"],
  inputEq: eq.fromEquals(constTrue),
  inputCodec: t.type({
    feature: t.string,
  }),
  outputCodec: t.boolean,
});
