import { useFormatMessage } from "../../../intl";
import {
  Box,
  buttonLink,
  Form,
  FormRow,
  PasswordField,
  Space,
  Body,
  Heading,
  FormSection,
  PasswordFieldWithCriteria,
  PasswordStrength,
  useIsTouchScreen,
  useForm,
  passwordStrengthCalculator,
  fieldIssues,
} from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { option, record, taskEither } from "fp-ts";
import { pipe } from "fp-ts/function";
import { NextButton } from "../../../Common/NextButton";
import { useEffect, useState } from "react";
import { useCriteria } from "../../../Common/useCriteria";
import { useValidators } from "../../../Common/useValidators";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { SmartKeyActivationDialog } from "../../../Common/Dialogs/SmartKeyActivationDialog";
import { PersonalDataProcessingDisclaimer } from "../../../Common/PersonalDataProcessingDisclaimer/PersonalDataProcessingDisclaimer";
import { TaskEither } from "fp-ts/TaskEither";

type Props = {
  next: (pin: NonEmptyString) => unknown;
  setUkontoPinStep: () => unknown;
  onComplete?: (password: NonEmptyString) => TaskEither<unknown, unknown>;
  disabled?: boolean;
};

export function SecurityPin(props: Props) {
  useEffect(() => {
    const { setUkontoPinStep } = props;
    setUkontoPinStep();
    // Reason: call the setUkontoKYCStep function once instead of multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { next, onComplete } = props;
  const formatMessage = useFormatMessage();
  const isTouchScreen = useIsTouchScreen();
  const [
    smartKeyActivationModalOpen,
    setSmarKeyActivationModalOpen,
  ] = useState<boolean>(false);
  const { securityPinCriteria, pinStrengthLabel } = useCriteria();
  const { validPin, pinMatches } = useValidators();
  const numberOfPinCriteria = pipe(
    securityPinCriteria,
    record.keys,
    a => a.length
  );

  const { fieldProps, handleSubmit, setValues, values } = useForm(
    {
      initialValues: {
        securityPin: { value: "", strength: "weak" as PasswordStrength },
        repeatedSecurityPin: "",
      },
      fieldValidators: values => ({
        securityPin: validPin,
        repeatedSecurityPin: pinMatches(values.securityPin),
      }),
    },
    {
      onSubmit:
        onComplete != undefined
          ? ({ securityPin }) => onComplete(securityPin)
          : ({ securityPin }) => taskEither.fromIO(() => next(securityPin)),
    }
  );

  const conditionalIssuesProps =
    fieldProps("securityPin").value?.value.length > 8
      ? {
          issues: option.some(
            fieldIssues.errors([
              formatMessage("Form.fieldError.maxLength", { max: 8 }),
            ])
          ),
        }
      : {};

  return (
    <Box column shrink>
      <Box column shrink>
        <Heading size="x-small" weight="medium" color={palette.neutral700}>
          {formatMessage("CreateProfile.securityPin.description.primary")}
        </Heading>
        <Space units={2} />
        <Body size="small" weight="regular" color={palette.neutral700}>
          {[
            formatMessage("CreateProfile.securityPin.description.secondary"),
            formatMessage("CreateProfile.securityPin.description.tertiary"),
            buttonLink(
              () => setSmarKeyActivationModalOpen(true),
              formatMessage("CreateProfile.securityPin.description.smartKey")
            ),
          ]}
        </Body>
      </Box>
      <Space units={10} />
      <Form>
        <FormSection>
          <FormRow type="1-1">
            <PasswordFieldWithCriteria
              {...fieldProps("securityPin")}
              placeholder={formatMessage(
                "CreateProfile.securityPin.placeholder"
              )}
              onChange={securityPin =>
                setValues({
                  securityPin,
                  // re-trigger validation of repeatedSecurityPin
                  repeatedSecurityPin: values.repeatedSecurityPin,
                })
              }
              {...conditionalIssuesProps}
              label={formatMessage("CreateProfile.securityPin.label")}
              criteria={securityPinCriteria}
              strength={passwordStrengthCalculator({
                baseCriteria: [],
                goodThreshold: numberOfPinCriteria,
                strongThreshold: numberOfPinCriteria,
              })}
              strengthLabel={pinStrengthLabel}
              virtualKeyboardInputType="numeric"
              showHideButton={
                isTouchScreen
                  ? option.some({
                      hideLabel: formatMessage("Hide"),
                      showLabel: formatMessage("Show"),
                    })
                  : option.none
              }
              disabled={props.disabled}
            />
            <Box />
          </FormRow>
          <FormRow type="1-1">
            <PasswordField
              {...fieldProps("repeatedSecurityPin")}
              placeholder={formatMessage(
                "CreateProfile.repeatedSecurityPin.placeholder"
              )}
              label={formatMessage("CreateProfile.repeatedSecurityPin.label")}
              virtualKeyboardInputType="numeric"
              showHideButton={
                isTouchScreen
                  ? option.some({
                      hideLabel: formatMessage("Hide"),
                      showLabel: formatMessage("Show"),
                    })
                  : option.none
              }
              disabled={props.disabled}
            />
            <Box />
          </FormRow>
        </FormSection>
      </Form>
      <Space units={10} />
      {isTouchScreen && (
        <PersonalDataProcessingDisclaimer
          label={formatMessage(
            "CreateProfile.securityPassword.personalData.agreement"
          )}
        />
      )}
      <Space units={10} />
      <Box hAlignContent="right">
        <NextButton action={handleSubmit} disabled={props.disabled} />
      </Box>

      {smartKeyActivationModalOpen && (
        <SmartKeyActivationDialog
          onDismiss={() => setSmarKeyActivationModalOpen(false)}
        />
      )}
    </Box>
  );
}
