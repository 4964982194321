import { useState } from "react";
import {
  Form,
  ReadOnlyField,
  FormSection,
  FeedbackDialog,
  AlertDialog,
  UCWarningIcon,
} from "design-system";
import { taskEither } from "fp-ts";
import { pipe } from "fp-ts/function";
import { useFormatMessage } from "../../intl";
import { useCommand, useQuery } from "../../useAPI";
import * as apis from "./api";
import * as remoteData from "../../RemoteData";

type Props = {
  onCancel: () => unknown;
  onConfirm: () => unknown;
};

export function SendContractsViaEmailDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const sendDocumentsCommand = useCommand(apis.sendDocumentsCommand);
  const [status, setStatus] = useState<"base" | "success" | "error">("base");

  const [emailQuery] = useQuery(apis.userEmail);

  const userEmail = pipe(
    emailQuery,
    remoteData.fold(
      () => "",
      () => "",
      ({ email }) => email
    )
  );

  const submitHandler = pipe(
    sendDocumentsCommand(),
    taskEither.bimap(
      () => setStatus("error"),
      () => setStatus("success")
    )
  );

  switch (status) {
    case "base":
      return (
        <AlertDialog
          type="confirmation"
          title={formatMessage(
            "SummaryAndDownload.Summary.contracts.email.title"
          )}
          message={formatMessage(
            "SummaryAndDownload.Summary.contracts.email.description"
          )}
          onCancel={props.onCancel}
          onDismiss={props.onCancel}
          onConfirm={submitHandler}
          cancelLabel={formatMessage(
            "SummaryAndDownload.Summary.contracts.email.cancel"
          )}
          confirmLabel={formatMessage(
            "SummaryAndDownload.Summary.contracts.email.submit"
          )}
          icon={UCWarningIcon}
        >
          <Form>
            <FormSection>
              <ReadOnlyField
                size="medium"
                value={userEmail}
                label={formatMessage(
                  "SummaryAndDownload.Summary.contracts.email.verifiedEmailLabel"
                )}
              />
            </FormSection>
          </Form>
        </AlertDialog>
      );
    case "error":
      return (
        <FeedbackDialog
          title={formatMessage(
            "SummaryAndDownload.Summary.contracts.email.error.title"
          )}
          type="negative"
          cta={{
            label: formatMessage("Ok"),
            action: props.onConfirm,
          }}
        />
      );
    case "success":
      return (
        <FeedbackDialog
          title={formatMessage(
            "SummaryAndDownload.Summary.contracts.email.confirmation.title"
          )}
          type="success"
          cta={{
            label: formatMessage("Ok"),
            action: props.onConfirm,
          }}
        />
      );
  }
}
